import {Component} from '@angular/core';
import {OrderConfirmationApiService} from '../api/order-confirmation-api.service';
import {ToastrService} from 'ngx-toastr';
import {TranslatorService} from '../../../../service/translator-service/translator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent {
  // order
  public orderId: any;
  public order: any;
  public address: any;
  public isAnswer: boolean;
  
  // telegram data
  private tgApp: any;
  private tgSupplier: any;
  
  public isAddressCopied: boolean;
  public state: any;
  public isLoading: boolean;
  public isLoaded: boolean;

  constructor(
    private orderConfirmationApiService: OrderConfirmationApiService,
    private toasterService: ToastrService,
    private translatorService: TranslatorService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    const windowObject = window as any;
    this.tgApp = windowObject.Telegram.WebApp;
    if (this.tgApp?.initDataUnsafe) {
      this.tgSupplier = this.tgApp.initDataUnsafe.user;
    }
    this.getOrder();
  }

  getOrder() {
    this.route.params.subscribe(async params => {
      this.isLoaded = false;
      this.orderId = params.orderId;
      try {
        const order = await lastValueFrom(this.orderConfirmationApiService.apiGetConfirmationOrderDetail(this.orderId, this.tgSupplier.id));
        if (!order) {
          await this.router.navigate(['404']);
          return;
        }
        this.order = order;
        this.isAnswer = order.supplierContract.isAnswer;
        if (this.isAnswer) {
          this.order.orderItemList = order.supplierContract.itemList;
        }
        for (const item of this.order.orderItemList) {
          item['maxQty'] = item.qty;
        }
        this.address = this.order.deliveryAddress;

      } catch (e) {
        console.error('get contract order failed', e);
        await this.router.navigate(['404']);
      }
      this.isLoaded = true;
    });
  }

  async copyAddress() {
    let contactText = '';
    let contactCount = 1;
    for (const contact of this.address.contactList) {
      contactText += `Contact ${contactCount}: +${contact.countryCode} ${contact.phone} \n`
      contactCount++;
    }
    const text = `Location Name: ${this.address.name || '-'} \nAddress: ${this.address.address.trim() || '-'} \nMap: https://www.google.com/maps/place/${this.address.latitude},${this.address.longitude} \n${contactText}`

    if (navigator && navigator.permissions && navigator.clipboard) {
      try {
        const permissionStatus = await navigator.permissions.query({name: 'clipboard-write' as PermissionName});
        if (permissionStatus.state === 'granted') {
          await navigator.clipboard.writeText(text)
          this.isAddressCopied = true;
        }
      } catch (e) {
        console.error('copy failed', e);
      }
    } else {
      this.copyToClipboardLegacy(text);
    }
  }
  
  copyToClipboardLegacy(text) {
    const dom = document.createElement('textarea');
    dom.style.position = 'fixed';
    dom.style.left = '0';
    dom.style.top = '0';
    dom.style.opacity = '0';
    document.body.appendChild(dom);
    dom.value = text;
    dom.focus();
    dom.select();
    document.execCommand('copy');
    document.body.removeChild(dom);
    this.isAddressCopied = true;
  }

  decrementQty(item) {
    if (item.qty === 1) {
      this.onDeleteItem(item);
      return;
    }
    
    item.qty--;
  }
  
  async incrementQty(item) {
    if (item.qty >= item.maxQty) {
      const maxQtyInfoText = await this.translatorService.translate('supplierOrder_edit_max_quantity_text');
      this.toasterService.info(maxQtyInfoText,null,{ positionClass: 'toast-top-left'});
      return;
    }
    
    item.qty++;
  }
  
  onDeleteItem(item) {
    item.isDeleted = true;
  }
  
  onRevertDelete(item) {
    item.isDeleted = false;
    item.qty = item.maxQty;
  }

  async onRefuseOrder() {
    this.isLoading = true;
    const buildContract = {
      status: 'reject',
      removeItemList: this.order.orderItemList,
      itemList: []
    }
    try {
      await lastValueFrom(this.orderConfirmationApiService.apiUpdateConfirmationOrderDetail(this.orderId, this.tgSupplier.id, buildContract));
      const orderRefuseText = await this.translatorService.translate('supplierOrder_order_refuse_text');
      this.toasterService.success(orderRefuseText,null,{ positionClass: 'toast-top-left'});
      setTimeout(() => {
        this.tgApp.close();
      }, 2000);
    } catch (e) {
      console.error('error try to refuse order', e);
      const message = await this.translatorService.translate('supplier_order_there_was_an_error_trying_contact_to_manager');
      this.toasterService.error(message,null,{ positionClass: 'toast-top-left'});
    }
    this.isLoading = false;
    this.isAnswer = true;
  }

  async onConfirmOrder() {
    this.isLoading = true;
    let status = 'accept';
    let removeItemList = [];
    let itemList = [];

    for (const item of this.order.orderItemList) {
      if (item.qty < item.maxQty || item.isDeleted) {
        status = 'reject';
        let removeItem = _.clone(item);
        removeItem.qty = item.isDeleted ? item.maxQty : item.maxQty - item.qty;
        removeItemList.push(removeItem);
      }
      
      if (!item.isDeleted) {
        itemList.push(item);
      }
    }
    
    const buildContract = {status, removeItemList, itemList}
    try {
      await lastValueFrom(this.orderConfirmationApiService.apiUpdateConfirmationOrderDetail(this.orderId, this.tgSupplier.id, buildContract));
      if (itemList.length === 0 ) {
        const orderRefuseText = await this.translatorService.translate('supplierOrder_order_refuse_text');
        this.toasterService.success(orderRefuseText,null,{ positionClass: 'toast-top-left'});
      } else if (status === 'accept') {
        const orderConfirmText = await this.translatorService.translate('supplierOrder_order_confirm_text');
        this.toasterService.success(orderConfirmText,null,{ positionClass: 'toast-top-left'});
      } else {
        const orderConfirmText = await this.translatorService.translate('supplierOrder_order_partial_confirm_text');
        this.toasterService.success(orderConfirmText,null,{ positionClass: 'toast-top-left'});
      }
      setTimeout(() => {
        this.tgApp.close();
      }, 2000);
    } catch (e) {
      console.error('error try to refuse order', e);
      const message = await this.translatorService.translate('supplier_order_there_was_an_error_trying_contact_to_manager');
      this.toasterService.error(message,null,{ positionClass: 'toast-top-left'});
    }
    this.isLoading = false;
    this.isAnswer = true;
  }
}

