import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import * as sc from 'socketcluster-client';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public socket;
  public channels: any[];
  constructor() {
    this.channels = [];
  }

  public init(): void {
    this.createSocket();
  }

  private createSocket(): void {
    if (!this.socket) {
      this.socket = sc.create({
        hostname: environment.socketHost,
        port: environment.socketPort,
        path: '/socketcluster/',
        autoReconnect: true,
        autoConnect: true,
        secure: environment.socketSecure
      });
    }
  }

  public subscribe(channelName: string): any {
    const subscribedChannel = this.socket.subscribe(channelName);
    this.channels = this.channels.filter(channel => channel.name !== subscribedChannel.name);
    this.channels.push(subscribedChannel);
    return subscribedChannel;
  }

  public unsubscribe(channelName: string): void {
    this.socket.unsubscribe(channelName);
    this.channels = this.channels.filter(channel => channel.name !== channelName);
  }

  public async sendSocket(channel: string, message: any): Promise<any> {
    return await this.socket.invokePublish(channel, message);
  }

  public destroySocket(): void {
    if (!this.socket) { return; }
    for (const channel of this.channels) { this.unsubscribe(channel.name); }
    this.socket._destroy();
    this.socket = null;
    this.channels = [];
  }
}
