<div class="scrollY h100vh">
  <div *ngIf="isLoaded" class="container">
    <div class="row gap-4">
      <div class="product-order">
        <div class="fullHeight scrollY fadeIn">
          <table  class="fullWidth">
            <!--table header-->
            <thead>
            <tr class="table-header-h75 fix-table-header" >
              <th></th>
              <th>
                <div>{{'supplierOrder_table_header_product'| translate}}</div>
              </th>
              <th class="textCenter d-none d-sm-table-cell">
                <div>{{'supplierOrder_table_header_packing'| translate}}</div>
              </th>
              <th class="textCenter">
                <div>{{'supplierOrder_table_header_quantity'| translate}}</div>
              </th>
              <th *ngIf="!isAnswer" class="textCenter d-none d-sm-table-cell">
                <div>{{'supplierOrder_table_header_action'| translate}}</div>
              </th>
            </tr>
            </thead>

            <!--table body-->
            <tbody>
            <tr *ngFor="let item of order?.orderItemList; let i = index" class="table-row-h75">
              <td class="textCenter">
                <app-product-avatar [class.opacity-25]="item.isDeleted" [src]="item?.menuItem?.thumbnail" [iconSize]= "30"  [style]="{width: '50px', height: '50px'}"></app-product-avatar>
              </td>
              <td class="">
                <div class="fxColumn justify-content-between gap-1">
                  <div class="fontBold font16" [class]="item.isDeleted ? 'text-decoration-line-through colorGray' : ''">{{item?.menuItem?.name}}</div>

                  <!-- hide for larger screen --->
                  <div class="colorGray font16 d-sm-none" [class.text-decoration-line-through]="item.isDeleted">{{item?.menuItem?.packing}}{{item?.menuItem?.unitOfMeasurement}}</div>
                  <!-- <div class="fontBold font16 d-sm-none">${{item?.item?.defaultPrice}}</div> --->
                </div>
              </td>
              <td class="textCenter d-none d-sm-table-cell">
                <div class="colorGray font16" [class.text-decoration-line-through]="item.isDeleted">{{item?.menuItem?.packing}}{{item?.menuItem?.unitOfMeasurement}}</div>
              </td>
              <td class="textCenter">
                <div class="fxColumn gap-2">
                  <div *ngIf="!isAnswer" class="fxRow fxRowCenter fxColumnCenter cursorPointer d-sm-none">
                    <i *ngIf="!item.isDeleted" (click)="onDeleteItem(item)" class="fa fa-trash font20 colorGray" aria-hidden="true"></i>
                    <i *ngIf="item.isDeleted" (click)="onRevertDelete(item)" class="fa fa-reply font20 colorGray" aria-hidden="true"></i>
                  </div>

                  <div *ngIf="!item.isDeleted && !isAnswer" class="qty-box">
                    <div class="input-group flex-nowrap">
                  <span>
                    <button (click)="decrementQty(item)" type="button" class="btn quantity-left-minus" data-type="minus">
                       <i class="ti-angle-left"></i>
                    </button>
                  </span>
                      <input type="text" name="quantity" class="form-control h30 w50 col-sm-1 bg-transparent" [value]="item?.qty" disabled/>
                      <span>
                    <button (click)="incrementQty(item)" type="button" class="btn quantity-right-plus" data-type="plus">
                      <i class="ti-angle-right"></i>
                    </button>
                  </span>
                    </div>
                  </div>
                  <div *ngIf="item.isDeleted">
                    <del class="text-decoration-line-through"> X {{ item.maxQty }} </del>
                  </div>
                  <div *ngIf="isAnswer">
                    <div class="font16"> X {{ item.qty }} </div>
                  </div>
                </div>
              </td>
              <td *ngIf="!isAnswer" class="d-none d-sm-table-cell">
                <div class="fxRow fxRowCenter fxColumnCenter cursorPointer">
                  <i *ngIf="!item.isDeleted" (click)="onDeleteItem(item)" class="fa fa-trash font20 colorGray" aria-hidden="true"></i>
                  <i *ngIf="item.isDeleted" (click)="onRevertDelete(item)" class="fa fa-reply font20 colorGray" aria-hidden="true"></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="order-success-sec light-layout rounded-2 p-3">
        <div>
          <div class="fontBold colorPrimary font16">{{ 'supplierOrder_delivery_address_section_title' | translate }}</div>
          <div class="fxRow">
            <app-product-avatar [src]="address?.photoUrl" [iconSize]= "50"  [style]="{width: '70px', height: '70px'}"></app-product-avatar>
            <div class="fxColumn fxRowCenter ml6">
              <div class="fontBold colorPrimary">{{address?.name}}</div>
              <div class="colorGray font14">{{address?.address}}</div>
              <div class="fxRow fxRowSpaceBetween">
                <button (click)="copyAddress()" type="button" class="btn btn btn-solid btn-sm px-2 py-1">
                  <i [class]="isAddressCopied ? 'ti-check-box' : 'ti-clipboard'"></i>
                  {{ 'supplierOrder_copy_map_button' | translate }}
                </button>

                <a target="_blank" [href]="'https://www.google.com/maps/place/' + address?.latitude + ',' + address?.longitude">
                  <button type="button" class="btn btn btn-solid btn-sm px-2 py-1">{{ 'supplierOrder_open_map_button' | translate }}</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="fxRow fxColumnCenter">
          <div class="fontBold colorPrimary font16 mr6">{{ 'supplierOrder_delivery_date' | translate }}:</div>
          <div *ngIf="order.deliveredAt" class="font16">{{ order.deliveredAt | date: 'dd/MM/yyyy hh:mm a' }}</div>
          <div *ngIf="!order.deliveredAt" class="font16">{{ 'supplierOrder_delivery_date_asap' | translate }}</div>
        </div>
        <div *ngIf="address?.contactList && address.contactList[0]">
          <div class="fontBold colorPrimary font16">{{ 'supplierOrder_contact_list_section_title' | translate }}</div>
          <div *ngFor="let contact of address.contactList" class="fxRow gap6">
            <div *ngIf="contact.firstname || contact.lastname">{{ contact.firstname }}&nbsp;{{ contact.lastname }}</div>
            <div *ngIf="contact.phone">&nbsp;+{{ contact.countryCode + contact.phone }}</div>
          </div>
        </div>
      </div>

      <div *ngIf="!isLoading && !isAnswer" class="fxRow fxRowSpaceBetween">
        <a (click)="onRefuseOrder()" class="btn btn-solid">{{ 'supplierOrder_refuse_button' | translate }}</a>
        <a (click)="onConfirmOrder()" class="btn btn-solid">{{ 'supplierOrder_confirm_button' | translate }}</a>
      </div>
      <div *ngIf="isLoading" class="fxRow fxRowCenter fxColumnCenter">
        <img class="w50" src="./assets/image/img/product/loader.gif" alt="loading">
      </div>
      <div *ngIf="isAnswer" class="fxRow fxRowCenter fxColumnCenter">
        <h2 class="textCenter colorSuccess">{{ 'supplierOrder_order_already_answer_text' | translate }}</h2>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!isLoaded">
  <div class="row gap-4">
    <div class="product-order">
      <div class="fullHeight scrollY fadeIn">
        <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '90px' }"></ngx-skeleton-loader>
      </div>
    </div>

    <div class="rounded-2 p-3">
      <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '250px' }"></ngx-skeleton-loader>
    </div>

    <div>
      <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '50px' }"></ngx-skeleton-loader>
    </div>
  </div>
</div>
