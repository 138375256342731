import { Injectable } from '@angular/core';
import {BaseService} from "../../../../../service/base-service/base.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class KpiExpenseByOutletApiService {

  constructor(
      private baseService: BaseService
  ) {
  }
  apiGetKpiExpenseListByOutletAndMonthYear(query, next, limit = 15): Observable<any> {
    const queryString = this.baseService.buildQuery(query);
    return this.baseService.get(`/v3/user/b2b/get/expense/list/${next}/${limit}${queryString}`);
  }
  apiGetKpiExpenseByOutlet(): Observable<any> {
    return this.baseService.get('/v3/user/b2b/kpi/get/expense/by/outlet');
  }
}
