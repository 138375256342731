import { Injectable } from '@angular/core';

export class LocalStorageKey {
  static user = 'user';
  static token = 'token';
  static language = 'language';
  static theme = 'theme';
}

@Injectable({
  providedIn: 'root'
})


export class LocalStorageService {

  private allKey = [
    LocalStorageKey.user,
    LocalStorageKey.token
  ];

  private keepKey = [];

  constructor() { }

  save(key: LocalStorageKey, data): any {
    localStorage.setItem(key.toString(), JSON.stringify(data));
    return data;
  }

  load(key: LocalStorageKey): any {
    const data = localStorage.getItem(key.toString());
    return JSON.parse(data) || null;
  }

  clear(): void {
    this.allKey.forEach(key => {
      if (!this.keepKey.includes(key)) {
        localStorage.removeItem(key);
      }
    });
  }

  focusClear(): void { localStorage.clear(); }
}
