import { Injectable } from '@angular/core';
import {LoginApiService} from '../api/login-api.service';
import {Router} from '@angular/router';
import {BasketService} from '../../shop/basket/service/basket.service';
import {SocketService} from "../../../service/socket-service/socket.service";
import {TicketService} from "../../ticket/service/ticket.service";
import {BaseService} from "../../../service/base-service/base.service";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
      private loginApiService: LoginApiService,
      private router: Router,
      private basketService: BasketService,
      private socketService: SocketService,
      private ticketService: TicketService,
      private baseService: BaseService,

  ) { }
  
  async login(credential: any): Promise<boolean> {
    this.clearUserLocalStorage();
    try {
      const response = await this.loginApiService.apiLoginUser(credential).toPromise();
      await localStorage.setItem('token', JSON.stringify(response.jwt));
      await localStorage.setItem('user', JSON.stringify(response.user));
      this.basketService.itemBasketQtyMap = {};
      this.basketService.getBasketItem();
      return true;
    } catch (e) {
      console.error('try login failed!', e);
      return false;
    }
  }
  
  private clearUserLocalStorage(): void {
    this.baseService.token = null;
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('cartItems');
  }
  
  logout():void {
    this.clearUserLocalStorage();
    this.router.navigate(['']);
    this.ticketService.destroy();
    this.socketService.destroySocket();
  }

  checkLogin(): void {
    if (!this.isLogin()) {
      this.router.navigate(['']);
    }
  }
  
  isLogin(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    }
    return false;
  }
}
