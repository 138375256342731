import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {NavService} from '../nav-service/nav.service';

@Injectable({
  providedIn: 'root'
})

// code from https://itnext.io/cache-components-with-angular-routereusestrategy-3e4c8b174d5f
export class CacheRouteService implements RouteReuseStrategy {
  storedRouteHandles = new Map<string, DetachedRouteHandle>();
  allowRetrieveCache = {
    '/home/shop': true,
    '/home/search': true,
  };
  scrollPos = {
    '/home/shop' : 0,
    '/home/search': 0
  }

  constructor(private navService: NavService) {}

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr:  ActivatedRouteSnapshot): boolean {
    const isTheSameRoute = future.routeConfig === curr.routeConfig;
    if (isTheSameRoute) {
      this.allowRetrieveCache['/home/shop'] = false;
      this.allowRetrieveCache['/home/search'] = false;
    } 
    else {
      if (curr.routeConfig && curr.routeConfig.path) {
        this.allowRetrieveCache['/home/search'] = this.getPath(future) === '/home/search' && this.getFullPath(future) === this.navService.previousRoute;
        this.allowRetrieveCache['/home/shop'] = this.getPath(future) === '/home/shop' && curr.routeConfig.path === 'product/:productId';
      }
      
      // save previous route when goto detail page
      if (['/home/shop', '/home/search'].includes(this.getPath(curr))) {
        this.navService.previousRoute = this.getFullPath(curr);
      }
      if (future.title && future.title === 'login') {
        this.storedRouteHandles.clear();
        this.navService.previousRoute = '/home/search';
      }
    }

    return isTheSameRoute;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRouteHandles.get(this.getPath(route)) as DetachedRouteHandle;
  }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.allowRetrieveCache[path]) {
      return this.storedRouteHandles.has(this.getPath(route));
    }

    return false;
  }
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route);
    if (this.allowRetrieveCache.hasOwnProperty(path)) {
      const dom = document.getElementById('v-pills-tabContent');
      if (dom) { this.scrollPos[path] = dom.scrollTop; }
      return true;
    }
    return false;
  }
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
    const path = this.getPath(route);
    this.storedRouteHandles.set(path, detachedTree);
    if (this.allowRetrieveCache[path]) {
      setTimeout(() => {
        const dom = document.getElementById('v-pills-tabContent');
        if (dom) { dom.scrollTo(0, this.scrollPos[path]); }
      }, 50);
    }
  }
  private getPath(route: ActivatedRouteSnapshot): string {
    return route['_routerState'].url.split('?')[0];
  }
  private getFullPath(route: ActivatedRouteSnapshot): string {
    return route['_routerState'].url;
  }
}
