import {CommonObject} from '../../../model/common-object/common-object';

export class Breadcrumb extends CommonObject {
  title: string;
  breadcrumbList: BreadcrumbLink[]
}

export interface BreadcrumbLink {
  label: string,
  link: string
}
