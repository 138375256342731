import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from '../../../native/dialog/model/dialog';
import {UserService} from '../../../native/user/service/user.service';
import {ToastrService} from 'ngx-toastr';
import {TranslatorService} from '../../../service/translator-service/translator.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
// dialog=changePasswordDialog
export class ChangePasswordDialogComponent implements OnInit{
  @Input() dialog: Dialog;
  
  public oldPassword: string;
  public newPassword: string;
  public confirmNewPassword: string;
  
  public isDialog: Boolean;
  
  public error: any;
  public loading: boolean;

  constructor(
    private userService: UserService,
    private toasterService: ToastrService,
    private translatorService: TranslatorService
  ) {
    this.error = {};
  }
  
  ngOnInit() {
    this.isDialog = !!this.dialog;
  }

  async onSave() {
    if (!this.checkError()) {
      this.loading = true;
      const status = await this.userService.changePassword(this.oldPassword, this.newPassword);
      if (!status || status === 0) {
        this.error.invalid = true;
      } else {
        this.toasterService.success(await this.translatorService.translate('myAccount_change_pw_dialog_update_success_toaster'));
        this.onCancel();
      }
      this.loading = false;
    }
  }

  onCancel() {
    this.oldPassword = '';  
    this.newPassword = '';  
    this.confirmNewPassword = '';
    this.error = {};
    
    if (this.isDialog) {
      this.dialog.close();
    }
  }
  
  checkError() {
    const pwRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/;
    
    if (this.newPassword !== this.confirmNewPassword || !this.newPassword || this.newPassword === '') {
      this.error.notMatch = true;
      return true;
    }
    
    if (!pwRegex.test(this.newPassword)) {
      this.error.format = true;
      return true;
    }
    
    return false;
  }
}
