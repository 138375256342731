<div *ngIf="order" class="maxHeight85vh w500 fxColumn px-2 py-2">
  <div class="mb-4 fxRow justify-content-between align-items-center">
    <h3 class="col-11">
      Order #{{ order.number }}
      <span class="badge rounded-pill custom-badge"
            [class.bg-info]="order.status === 'CONFIRMED'"
            [class.bg-danger]="order.status === 'PROCESSING'"
            [class.bg-warning]="order.status === 'PENDING'"
            [class.bg-secondary]="order.status === 'REJECTED' || order.status === 'INCOMPLETE'"
      >{{ order.status }}</span>
    </h3>
    <i class="ti-close font20 col-1 cursorPointer" (click)="dialog.close()"></i>
  </div>
  <div class="fullHeight scrollY fadeIn">
    <table  class="fullWidth">
      <!--table header-->
      <thead>
      <tr class="table-header-h75 fix-table-header" >
        <th>{{ 'myAccount_order_detail_dialog_table_head_image' | translate }}</th>
        <th>
          <div>{{'myAccount_order_detail_dialog_table_head_name'| translate}}</div>
        </th>
        <th *ngIf="userService.isAllowViewPrice" class="textCenter d-none d-sm-table-cell">
          <div>{{ 'myAccount_order_detail_dialog_table_head_price' | translate }}</div>
        </th>
        <th class="textCenter d-none d-sm-table-cell">
          <div>{{'myAccount_order_detail_dialog_table_head_packing'| translate}}</div>
        </th>
        <th class="textCenter">
          <div>{{'myAccount_order_detail_dialog_table_head_quantity'| translate}}</div>
        </th>
      </tr>
      </thead>

      <!--table body-->
      <tbody>
      <tr *ngFor="let orderItem of order?.orderItemList; let i = index" class="table-row-h75">
        <td class="textCenter">
          <app-product-avatar [class.opacity-25]="orderItem.isDeleted" [src]="orderItem?.menuItem?.thumbnail" [iconSize]= "30"  [style]="{width: '50px', height: '50px'}"></app-product-avatar>
        </td>
        <td class="">
          <div class="fxColumn justify-content-between gap-1">
            <div class="fontBold font16" [class]="orderItem.isDeleted ? 'text-decoration-line-through colorGray' : ''">{{orderItem?.menuItem?.name}}</div>

            <!-- hide for larger screen --->
            <div class="colorGray font16 d-sm-none" [class.text-decoration-line-through]="orderItem.isDeleted">{{(orderItem.packing || orderItem?.menuItem?.packing) || '-'}} {{orderItem?.menuItem?.unitOfMeasurement}}</div>
            <!-- <div class="fontBold font16 d-sm-none">${{item?.item?.defaultPrice}}</div> --->
          </div>
        </td>
        <td *ngIf="userService.isAllowViewPrice" class="textCenter d-none d-sm-table-cell">
          <div class="fontBold font16" [class.text-decoration-line-through]="orderItem.isDeleted">{{orderItem.price | currency: productService.Currency.currency:'symbol'}}</div>
        </td>
        <td class="textCenter d-none d-sm-table-cell">
          <div class="colorGray font16" [class.text-decoration-line-through]="orderItem.isDeleted">{{(orderItem.packing || orderItem?.menuItem?.packing) || '-'}}</div>
        </td>
        <td class="textCenter">
          <div class="fxColumn gap-2">
            <div>
              <div class="font16"> X {{ orderItem.qty }} </div>
            </div>
          </div>
        </td>
      </tr>
      <tr *ngIf="!order.orderItemList || !order.orderItemList[0]">
        <td colspan="5" class="textCenter">
          <p class="font16">{{ 'myAccount_order_detail_dialog_table_empty' | translate }}</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="address" class="order-success-sec light-layout rounded-2 p-3">
    <div>
      <div class="fontBold colorPrimary font16">{{ 'myAccount_order_detail_dialog_delivery_address_section_title' | translate }}</div>
      <div class="fxRow">
        <app-product-avatar [src]="address?.photoUrl" [iconSize]= "50"  [style]="{width: '70px', height: '70px'}"></app-product-avatar>
        <div class="fxColumn fxRowCenter ml6">
          <div class="fontBold colorPrimary">{{address?.name}}</div>
          <div class="colorGray font14">{{address?.address}}</div>
        </div>
      </div>
    </div>
    <div class="fxRow fxColumnCenter my6">
      <div class="fontBold colorPrimary font16 mr6">{{ 'myAccount_order_detail_delivery_date' | translate }}:</div>
      <div *ngIf="order.deliveredAt" class="font16">{{ order.deliveredAt | date: 'dd/MM/yyyy hh:mm a' }}</div>
      <div *ngIf="!order.deliveredAt" class="font16">{{ 'myAccount_order_detail_asap' | translate }}</div>
    </div>
    <div *ngIf="address?.contactList && address.contactList[0]">
      <div class="fontBold colorPrimary font16">{{ 'myAccount_order_detail_dialog_contact_list_section_title' | translate }}</div>
      <div *ngFor="let contact of address.contactList" class="fxRow gap6">
        <div *ngIf="contact.firstname || contact.lastname">{{ contact.firstname }}&nbsp;{{ contact.lastname }}</div>
        <div *ngIf="contact.phone">&nbsp;+{{ contact.countryCode + contact.phone }}</div>
      </div>
    </div>
  </div>
</div>
