import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public today: Date;
  public todayNextYear: Date;
  constructor() {
    this.today = new Date();
    this.todayNextYear = new Date(this.today.getFullYear() + 1);
  }
}
