<!--footer section -->
<footer class="fadeIn footer-light">
  <section class="section-b-space light-layout">
    <div class="container">
      <div class="row justify-content-between footer-theme partition-f">
        <div class="col-lg-4 col-md-6">
          <div class="footer-title footer-mobile-title">
            <h4>{{'footer_about_title'| translate}}</h4>
          </div>
          <div class="footer-contant">
            <div class="footer-logo">
              <img src="./assets/image/logo/app-logo/icon.png" class="img-fluid w200" alt="logo">
            </div>
            <p>{{'footer_about_description' | translate}}</p>
            <div class="footer-social">
              <ul>
                <li>
                  <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                </li>
                <li>
                  <a href="javascript:void(0)"><i class="fa fa-telegram" aria-hidden="true"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="sub-title">
            <div class="footer-title">
              <h4>{{'footer_store_info_title'| translate}}</h4>
            </div>
            <div class="footer-contant">
              <ul class="contact-list">
                <li>
                  <i class="fa fa-map-marker"></i>
                  47, 294, Phum 5, Boeng Keng <br>
                  Kang Muoy, Chamkar Mon, <br>
                  Phnom Penh, 12302, Cambodia
                </li>
                <li><i class="fa fa-phone"></i>{{'footer_call_us_label'| translate}}: 123-456-7898</li>
                <li><i class="fa fa-envelope-o"></i>{{'footer_email_us_label'| translate}}: <a>support@moderniyholding.com</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="sub-footer">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end fontBold">
            <p><i class="fa fa-copyright" aria-hidden="true"></i> MODERNITY HOLDING {{ today | date:'y'}}-{{ today | date:'y'}}</p>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="footer-end">
            <div class="term-and-condition fxRow fxRowEnd py20">
              <div>
                <a routerLink="/privacy-policy" class="colorGray colorHoverBlack fontBold">Privacy policy</a>
              </div>
              <div class="ml24">
                <a routerLink="/term-and-condition" class="colorGray colorHoverBlack fontBold ">Term and condition</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</footer>
<!--footer section end -->
