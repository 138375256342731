import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Breadcrumb} from "../../../../native/breadcrumb/model/breadcrumb";
import {TranslatorService} from "../../../../service/translator-service/translator.service";
import {UserService} from "../../../../native/user/service/user.service";
import {TicketApiService} from "../../api/ticket-api.service";
import {Ticket, TicketMessage} from "../../model/ticket";
import {firstValueFrom} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import * as _ from 'lodash';
import {TicketService} from "../../service/ticket.service";

@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit, OnDestroy {
  @ViewChild('messageScrollDom') messageScrollDom: ElementRef;
  public breadCrumb: Breadcrumb;
  public messageInput: string;
  public isLoading: boolean;
  public messageList: TicketMessage[];
  public ticket: Ticket;
  public next: number;
  private messageSubScribe;
  constructor(
      private translatorService: TranslatorService,
      public userService: UserService,
      private ticketApiService: TicketApiService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private ticketService: TicketService
  ){
  this.messageInput = '';
     this.messageSubScribe = this.ticketService.eMessageSocket.subscribe(async (message) => {
      if (message.ticketId === this.ticket._id) {
        this.messageList.push(message);
        this.scrollToBottom();
      }
    });
  }
  async ngOnInit() {
    //get data from router
    this.ticket = JSON.parse(history.state['ticket']);
    await this.initBreadcrumb();
    await  this.getTicketMessage();
  }
  private async initBreadcrumb() {
    this.breadCrumb = new Breadcrumb({
      title: await this.translatorService.translate('ticket_breadcrumb_title'),
      breadcrumbList: [
        {
          label: await this.translatorService.translate('ticket_breadcrumb_link')
        }
      ]
    });
  }

  private async getTicketMessage(): Promise<void>{
    try {
      this.isLoading =  true;
      const response =  await firstValueFrom(this.ticketApiService.getTicketMessageList(this.ticket._id,0,10));
      this.messageList= response.list;
      this.next = response.next;
      this.isLoading = false;
      this.scrollToBottom();
    } catch(e) {
      this.isLoading = false;
      console.error('fail to get message', e);
    }
  }
  public async getMoreTicketMessage() : Promise<void> {
    if (!this.next) {
      return;
    }
    try {
      const response =  await firstValueFrom(this.ticketApiService.getTicketMessageList(this.ticket._id,this.next,10));
      this.messageList= response.list.concat(this.messageList);
      this.next = response.next;
    } catch(e) {
      console.error('fail to get more message', e);
    }
  }
  public async onAddComment(): Promise<void>{
    if(_.isEmpty(this.messageInput.trim())){
      return;
    }
    try {
      const response =  await firstValueFrom(this.ticketApiService.sendTicketMessage(this.ticket._id,{message: this.messageInput}));
      const messageObj = {
        sender: this.userService.user,
        sendTo: this.ticket.userId,
        type: 'supportTicket',
        ticketId: this.ticket._id,
        createdAt: response.createdAt,
        text: this.messageInput
      };
      await this.ticketService.sendSupportMessage(messageObj);
      this.messageList.push(response);
      this.scrollToBottom();
      this.messageInput = '';
    } catch(e) {
      console.error('fail to send message', e);
    }
  }
  public  isTextFieldIsEmpty(): boolean{
    return _.isEmpty(this.messageInput.trim());
  }
  public onBack(): void {
    this.router.navigateByUrl('/home/support');
  }
  private scrollToBottom(): void {
    if (!this.messageScrollDom) { return; }
    setTimeout(() => {
      this.messageScrollDom.nativeElement.scrollTo(0, this.messageScrollDom.nativeElement.scrollHeight);
    });
  }
  ngOnDestroy(){
    this.messageSubScribe.unsubscribe();
  }
}
