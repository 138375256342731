import { Injectable } from '@angular/core';
import {ClientSettingApiService} from "../../../service/client-setting-api-service/client-setting-api.service";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TermConditionService {
  public data: string;
  public isLoading: boolean;

  constructor(private clientSettingApiService: ClientSettingApiService) {
    this.data = '';
  }


  async onGetData(type: string): Promise<void> {
    try{
      this.isLoading =  true;
      const response =  await firstValueFrom(this.clientSettingApiService.getClientSetting());
      switch (type) {
        case 'privacyPolicy':
          this.data = response.privacyPolicy;
          break;
        case 'termCondition':
          this.data = response.termCondition;
          break;
      }
      this.isLoading = false;
    }catch(e){
      this.isLoading = false;
      console.error('fail to get data', e);
    }
  }
}
