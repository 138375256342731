<div>
    <app-header></app-header>
    <div class="h-no-header margin-header scrollY">
        <!--screen info-->
<!--        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>-->
        <!--section start-->
        <div class="container">
            <div *ngIf="!termConditionService.isLoading" class="mt24" [innerHTML]="termConditionService.data">
            </div>
            <ngx-skeleton-loader *ngIf="termConditionService.isLoading" [theme]="{ 'background-color': '#e8e8e8',
             height: 'calc(100vh - 258px)' }">
            </ngx-skeleton-loader>
        </div>
        <!--section end-->
        <app-footer></app-footer>
    </div>
</div>
