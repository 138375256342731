<div class="h100vh scrollY">
  <app-header></app-header>
  <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
  <!-- section start -->
  <section class="fadeIn login-page section-b-space">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h3>{{ 'login_title' | translate }}</h3>
          <div class="theme-card">
            <form class="theme-form">
              <div class="form-group">
                <label for="email">{{ 'login_form_email_label' | translate }}</label>
                <input [(ngModel)]="loginCredential.email" type="text" name="email" class="form-control form-error" id="email"
                       (input)="error = {}"
                       [class.border-danger]="error.wrongCredential"
                       [placeholder]="'login_form_email_label' | translate" required="">
              </div>
              <div class="form-group">
                <label for="review">{{ 'login_form_password_label' | translate }}</label>
                <input [(ngModel)]="loginCredential.password" [type]="isShowPassword ? 'text' :'password'" name="password"
                       class="form-control" id="review"
                       (input)="error = {}"
                       [class.border-danger]="error.wrongCredential"
                       [placeholder]="'login_form_password_hint' | translate" required="">
                <div id="toggle-password" (click)="onShowPasswordToggle()">
                  <i *ngIf="isShowPassword" class="fa fa-eye font18"></i>
                  <i *ngIf="!isShowPassword" class="fa fa-eye-slash font18" ></i>
                </div>
              </div>
              <div *ngIf="error.wrongCredential" class="alert alert-danger" role="alert">
                {{ 'login_form_input_error_text' | translate }}
              </div>
              <button (click)="onLogin()" class="btn btn-solid">{{ 'login_form_login_button' | translate }}</button>
            </form>
          </div>
        </div>
        <div class="col-lg-6 right-login">
          <h3>{{ 'login_be_partner_section_title' | translate }}</h3>
          <div class="theme-card authentication-right">
            <h6 class="title-font">{{ 'login_be_partner_section_create_account_text' | translate }}</h6>
            <p>{{ 'login_be_partner_section_create_account_description' | translate }}</p>
            <a href="mailto:contact@modernity.com">
              <button class="btn btn-solid">
                {{ 'login_be_partner_section_create_account_button' | translate }}
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Section ends-->
  <app-footer></app-footer>

</div>
