import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-checkout-success',
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss']
})
export class CheckoutSuccessComponent implements  OnInit{

  public data: any;

  constructor(private router:Router) {
    this.data = this.router.getCurrentNavigation()?.extras?.state ?? null;
    if(!this.data){
      this.router.navigateByUrl('home');
    }
  }
  ngOnInit() {

  }
}
