<div class="h-no-header fadeIn scrollY" >
    <app-breadcrumb [breadcrumb]="breadCrumb"></app-breadcrumb>
    <section class="section-b-space">
        <div class="container">
            <div class="fxRow">
                <div>
                    <div class="back-button fxRow fxRowCenter fxColumnCenter" (click)="onBack()">
                        <svg-icon src="./assets/image/icon/ticket/back-icon.svg"></svg-icon>
                    </div>
                </div>
                <div class="pl12">
                    <div class="font18 fontBold">{{'ticket_detail_ticket_number_label' | translate}}: #{{ticket.ticketNumber}}</div>
                    <div class="font16 fontSemiBold colorGray py6">{{'ticket_subject_label'| translate}}: {{ticket.subject}}</div>
                </div>
            </div>
            <div class="divider my12"></div>
            <div *ngIf="ticket.status === 'closed'"
                 class="py12 px24 backgroundPrimaryAlpha borderRadius">
                {{'ticket_ticket_close_for_comment'| translate}}
            </div>
            <div *ngIf="ticket.status === 'closed'" class="divider my12"></div>
            <div #messageScrollDom class="h450 scrollY"
                 infiniteScroll
                 [infiniteScrollUpDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolledUp)="getMoreTicketMessage()">
                <div *ngFor = "let message of messageList" class="fxColumn">
                    <div class="fxRow">
                        <app-avatar [user]="message.sender" [size]="40"></app-avatar>
                        <div class="fxColumn fullWidth pl24">
                            <div class="fxRow fxColumnCenter fxRowSpaceBetween mb12">
                                <div class="font16 fontBold colorPrimary">{{message.sender?.firstName}} {{message.sender?.lastName}}</div>
                                <div class="font12 fontLight colorGray">{{message.createdAt | date: 'dd MMM, yyyy hh:mm a'}}</div>
                            </div>
                            <div class="font14 colorGray">{{message.text}}</div>
                        </div>
                    </div>
                    <div class="divider my12"></div>
                </div>
            </div>
            <div *ngIf="ticket.status !=='closed'" class="fxRow mt12">
                <input
                        [(ngModel)]="messageInput"
                        (keyup.enter) = "onAddComment()"
                        class="form-control"
                        [placeholder]="'ticket_detail_reply_hint' | translate"
                />
                <button class="btn btn-solid" (click)="onAddComment()" [disabled]="isTextFieldIsEmpty()">
                    {{ 'ticket_detail_button_sent_title' | translate }}
                </button>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>

