import {EventEmitter, Injectable} from '@angular/core';
import {SocketService} from "../../../service/socket-service/socket.service";
import {UserService} from "../../../native/user/service/user.service";

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private isInit: boolean;
  public eMessageSocket: EventEmitter<any>;

  constructor(
      private socketService: SocketService,
      private userService: UserService,
  ) {
    this.eMessageSocket = new EventEmitter<any>();
  }

  public async init(): Promise<void> {
    if (this.isInit) { return; }
    this.isInit = true;
    await this.subscribeChatSocket();
  }

  destroy(): void { this.isInit = false; }

  private subscribeChatSocket(): void {
    const myChannel = this.socketService.subscribe(this.userService.user._id);
    this.handleReceiveChatSocket(myChannel);
  }
  private async handleReceiveChatSocket(channel): Promise<void> {
    for await (const message of channel) {
      if (message.type) {
        switch (message.type) {
          case 'supportTicket':
            this.handleToSubScribeMessage(message);
            break;
        }
        continue;
      }
    }
  }
  private handleToSubScribeMessage(message): void {
    this.eMessageSocket.emit(message);
  }
  // send support message
  async sendSupportMessage(message: any): Promise<void> {
    await this.socketService.sendSocket('supportTicket', message);
  }
}
