import {EventEmitter, Injectable} from '@angular/core';
import {BasketApiService} from '../api/basket-api.service';
import {firstValueFrom} from 'rxjs';
import {BasketItem} from "../../../../model/basket/basket";
import * as _ from "lodash";
import {TranslatorService} from "../../../../service/translator-service/translator.service";
import {ToastrService} from "ngx-toastr";
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  public basketItemList : BasketItem[];
  public itemBasketQtyMap : {};
  public isLoadingBasketItemMap : {} ;

  // debounce when change qty
  debounceChangeQty :any;
  // emit event when empty basket to return home when stay on checkout screen
  emptyBasketEventEmitter: EventEmitter<void>;

  constructor(
    private basketApiService: BasketApiService,
    private translatorService: TranslatorService,
    private toasterService: ToastrService,
    private router: Router
  ) {
    this.basketItemList = [];
    this.itemBasketQtyMap = {};
    this.isLoadingBasketItemMap = {};
    this.emptyBasketEventEmitter = new EventEmitter<void>();

    // debounce when change quantity of basket item
    this.updateItemInBasket = _.debounce(this.updateItemInBasket, 500, {leading: false}) as any;
  }

  // get basket item
  async getBasketItem(){

    try{
      const response = await firstValueFrom( this.basketApiService.getBasket());
      this.basketItemList = response.basketItems;
      if(_.isEmpty(this.basketItemList)){
        this.emptyBasketEventEmitter.emit();
      } else {
        for (const basketItem of this.basketItemList) { basketItem.packing = basketItem.item.packing; }
      }
    } catch (e) {
      this.basketItemList = [];
      console.error("Get Basket Item Fail",e);
    }

    if(_.isEmpty(this.itemBasketQtyMap)){
      for (const basketItem of this.basketItemList) { this.itemBasketQtyMap[basketItem.itemId] = basketItem.qty; }
    }
  }

  async addItemToBasket(item,itemQty : number) {
    this.isLoadingBasketItemMap[item._id] = true;
    try {
      const status = this.basketApiService.addItemToBasket({itemId:item._id, qty: itemQty});
      const basketItemResponse = await firstValueFrom(status) as BasketItem;
      delete  this.isLoadingBasketItemMap[item._id];
      this.itemBasketQtyMap[item._id] = itemQty;
     // If it doesn't exist, add it to basketItemList
      const exists = this.basketItemList.some(item => item._id === basketItemResponse._id);
      if (!exists) {
        this.basketItemList.push(basketItemResponse);
      }
      this.basketItemList = this.basketItemList.concat([]);
      const toasterText = await this.translatorService.translate('basket_add_item_to_basket_success',
          {vs1: itemQty.toString(), vs2: item.name});
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'}).onTap.subscribe(() => {
        this.router.navigateByUrl('home/shop/cart');
      });

      return true;
    } catch(e) {
      delete  this.isLoadingBasketItemMap[item._id];
      const toasterText = await this.translatorService.translate('basket_added_item_to_cart_failed',{vs1:item.name});
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('Add Item To Basket Fail!',e);
      return false;
    }
  }

  // change qty
  onChangeQty(direction: number,basketItem: BasketItem) {
    basketItem.qty = Math.max(basketItem.qty + direction, 0);
    if(basketItem.qty == 0){
      basketItem.isDeleting = true;
    }
    const basketItemChange = {
      qty: basketItem.qty,
      instruction: basketItem.instruction,
      options: basketItem.options
    };
    // assign value to local
    this.itemBasketQtyMap[basketItem.itemId] = basketItem.qty;
    this.updateItemInBasket(basketItem, basketItemChange);
  }

  //update item in basket
  async updateItemInBasket(basketItem: BasketItem, basketItemChanged): Promise<void> {
    try{
      const status = this.basketApiService.updateItemInBasket(basketItem._id, basketItemChanged);
      await firstValueFrom(status);
      if (basketItemChanged.qty === 0) {
        // remove item form local
        delete this.itemBasketQtyMap[basketItem.itemId];
        this.removeBasketItemFromLocal(basketItem);
        const toasterText = await this.translatorService.translate('basket_remove_item_from_basket_success',
            { vs1: basketItem.item?.name});
        this.toasterService.info(toasterText,null,{ positionClass: 'toast-top-left'});
      }
    }catch (e) {
      const toasterText = await this.translatorService.translate('basket_remove_item_from_basket_failed',
          { vs1: basketItem.item?.name});
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('Update Basket Item Fail!',e);
    }
  }

  // remove item for basket
  async onRemoveItemInBasket(basketItem: BasketItem) {
    basketItem.isDeleting = true;

    const basketItemChange = {
      qty: 0,
      instruction: basketItem.instruction,
      options: basketItem.options
    };
    try{
      //remove quantity from map
      delete this.itemBasketQtyMap[basketItem.itemId];

      const status = this.basketApiService.updateItemInBasket(basketItem._id,basketItemChange);
      await firstValueFrom(status);
      const toasterText = await this.translatorService.translate('basket_remove_item_from_basket_success',
          { vs1: basketItem.item?.name});
      this.toasterService.info(toasterText,null,{ positionClass: 'toast-top-left'});

      this.removeBasketItemFromLocal(basketItem);
    } catch (e) {
      basketItem.isDeleting = false;
      const toasterText = await this.translatorService.translate('basket_remove_item_from_basket_failed',
          { vs1: basketItem.item?.name});
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('Remove Basket Item Fail!',e);
    }
  }

  private removeBasketItemFromLocal(basketItem: BasketItem) {
    const index = this.basketItemList.findIndex(item => item._id === basketItem._id);
    if (index > -1) {
      this.basketItemList.splice(index, 1);
    }
    if(this.basketItemList.length === 0){
      this.emptyBasketEventEmitter.emit();
    }
  }

}
