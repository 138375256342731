import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {TranslatorService} from "../../../service/translator-service/translator.service";

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit{
  // init
  @Input() dialog: Dialog;

  public title;
  public description;

  constructor(
      private dialogService:DialogService,
      private translatorService: TranslatorService
  ){}

  async ngOnInit(): Promise<void> {
    this.title = this.dialog.options?.data?.title ? this.dialog.options?.data?.title : await this.translatorService.
    translate('confirm_dialog_title');
    this.description = this.dialog.options?.data?.description ? this.dialog.options?.data?.description : await this.translatorService.
    translate('confirm_dialog_description');
  }
  onOkay(){
    this.dialogService.close(this.dialog.name,this.dialog.options);
  }


}
