import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  isEnableChangePacking: boolean;

  constructor() { }
}
