<div *ngIf="user" class="noSelect avatar shadowPrimary" [style.border-radius.%]="borderRadiusPercentage">
    <div class="avatar-with-dot">
        <div class="avatar-div fxRow fxRowCenter fxColumnCenter" [style.width.px]="size" [style.height.px]="size"
             [style.background]="avatarBackground">
            <img *ngIf="user.profileUrl" class="avatar-image" [src]="user.profileUrl"
                 (error)="user.profileUrl = null" [draggable]="false" />
            <div *ngIf="!user.profileUrl" class="avatar-text" [style.font-size.px]="size/2">{{avatarText}}</div>
        </div>
    </div>
</div>
