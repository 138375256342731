import { Injectable } from '@angular/core';
import {Dialog, DialogOptions} from "../model/dialog";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public dialogList: Dialog[];

  constructor() {
    this.dialogList = [];
  }

  open(name: string, options?: DialogOptions): Dialog {
    const newDialog = new Dialog(name, options);
    const dialogSub = newDialog.eClose.subscribe(() => {
      newDialog.isClosing = true;
      setTimeout(() => {
        this.dialogList = this.dialogList.filter(dialog => dialog.name !== newDialog.name);
      }, 150);
      dialogSub.unsubscribe();
    });

    this.dialogList.push(newDialog);
    return newDialog;
  }

  close(name: string, options?: DialogOptions): void {
    const findDialog = this.dialogList.find(dialog => dialog.name === name);
    if (findDialog) {
      const lastIndex = this.dialogList.lastIndexOf(findDialog);
      this.dialogList[lastIndex].isClosing = true;
      setTimeout(() => {
        findDialog.close();
        this.dialogList[lastIndex].isClosing = false;
        this.dialogList = this.dialogList.filter(dialog => dialog.name !== name);
      }, 150);
    }
  }
}
