export const environment = {
  type: 'production',

  serverDomainName: 'https://api-b2b.modernityholding.com',
  imageProjectPrefix: 'mod-b2b-dev',

  // socket
  socketHost: 'sc-b2b.modernityholding.com',
  socketPort: 443,
  socketSecure: true,
};
