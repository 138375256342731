import {Component} from '@angular/core';
import {Breadcrumb} from "../../../native/breadcrumb/model/breadcrumb";
import {TermConditionService} from "../../term-and-condition/service/term-condition.service";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  public breadcrumb: Breadcrumb;

  constructor(public  termConditionService: TermConditionService){
    this.breadcrumb = new Breadcrumb({
      title: 'PRIVACY POLICY',
      breadcrumbList: [
        {
          label: 'Privacy Policy'
        }
      ]
    });
  }
  ngOnInit() {
    this.termConditionService.onGetData('privacyPolicy');
  }
}
