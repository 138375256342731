import { Component } from '@angular/core';
import {LoginService} from '../../login/service/login.service';
import {UserService} from '../../../native/user/service/user.service';
import {NavigationEnd, Router} from '@angular/router';
import {SocketService} from "../../../service/socket-service/socket.service";
import {TicketService} from "../../ticket/service/ticket.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  public isHomeScreen: boolean;

  constructor(
      private loginService: LoginService,
      private userService: UserService,
      private router: Router,
      private socketService: SocketService,
      private ticketService: TicketService
  ) {
    this.loginService.checkLogin();
    this.userService.init();
    this.init();
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        // event is an instance of NavigationEnd, get url!  
        const url = event.urlAfterRedirects.split('?')[0];
        const excludeUrl = ['/home/shop', '/home/search'];
        this.isHomeScreen = excludeUrl.includes(url);
      }
    })
  }
  private init(): void{
    // init chat
    this.socketService.init();
    this.ticketService.init();
  }
}
