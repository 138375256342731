import { Injectable } from '@angular/core';
import {BaseService} from "../../../service/base-service/base.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SearchProductApiService {

  constructor(private baseService: BaseService) { }


  searchProduct(limit: number,next: number ,body: any, selectedCategoryId: string = null): Observable<any> {
    const query = selectedCategoryId ? `?selectedCategoryId=${selectedCategoryId}` : '';
    return this.baseService.post(`/v1/user/search/item/${next}/${limit}${query}`, body);
  }

  apiGetHomeCategory(): Observable<any> { return this.baseService.get(`/v1/user/get/home/category`); }
}
