import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from "@angular/router";
import {BasketItem} from "../../../../model/basket/basket";
import {BasketService} from "../service/basket.service";

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent {
  @Output() eClose: EventEmitter<any>;
  @Input() cartItemList;

  // basket
  public basketItemList : BasketItem[];

  constructor(
      private router: Router,
      public basketService: BasketService,
  ){
    this.eClose = new EventEmitter<any>();
    this.basketService.getBasketItem();
  }

// close
  onClose(): void {
    this.eClose.emit();
  }

  // continue shopping
  onViewCart(): void{
    this.eClose.emit();
    this.router.navigateByUrl('home/shop/cart');
  }

}
