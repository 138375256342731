<header>
  <div class="navbar p-0">
    <div id="mySidenav" class="h-auto xl-positionStatic sidenav" [class.openSide]="sideMenuService.menuToggle">
      <a href="javascript:void(0)" class="sidebar-overlay" (click)="sideMenuService.sideMenuToggle()"
         (mouseover)="onHover(false)"></a>
      <nav id="unset" class="xl-h-no-header">
        <div (click)="sideMenuService.sideMenuToggle()" class="d-block d-xl-none">
          <div class="sidebar-back text-start">
            <i class="fa fa-angle-left pe-2" aria-hidden="true"></i> {{'side_menu_back_label' | translate}}
          </div>
        </div>
        <ul id="sub-menu" class="sidebar-menu">
          <li *ngFor="let menuItem of items">
            <!-- Sub -->
            <div *ngIf="menuItem.subCategoryList && menuItem.subCategoryList[0]"
                 (click)="toggleNavActive(menuItem)" 
                 class="fxRow fxColumnCenter fxRowSpaceBetween colorHoverPrimary cursorPointer"
                 [class.sm-item-selected]="sideMenuService.selectedMenu === menuItem">
              <div class="sm-item">
                <img *ngIf="menuItem.iconUrl" [src]="menuItem.iconUrl" class="img-fluid me-3 rounded-0" alt="">
                <img *ngIf="!menuItem.iconUrl" src="./assets/image/icon/shop/collection/product/grid-outline-icon.svg"
                     class="img-fluid me-3 rounded-0" alt="">
                {{getCategoryLabel(menuItem) }}
              </div>
              <div class="justify-content-between px-4">
                <i class="ti-plus font16 col-auto fw-bold"></i>
              </div>
            </div>

            <a *ngIf="!menuItem.subCategoryList || !menuItem.subCategoryList[0]" 
               (click)="sideMenuService.onSelectMenu(menuItem, null)" 
               href="javascript:void(0)" class="p-0"
               [class.sm-item-selected]="sideMenuService.selectedMenu === menuItem">
              <div class="sm-item">
                <img *ngIf="menuItem.iconUrl" [src]="menuItem.iconUrl" class="img-fluid me-3 rounded-0" alt="">
                <img *ngIf="!menuItem.iconUrl" src="./assets/image/icon/shop/collection/product/grid-outline-icon.svg"
                     class="img-fluid me-3 rounded-0" alt="">
                {{getCategoryLabel(menuItem) }}
              </div>
            </a>

            <!-- 2nd Level Menu -->
            <ul class="p-0" [id]="'menu-children-' + menuItem.label" *ngIf="menuItem.subCategoryList
            && menuItem.subCategoryList[0]">
              <li *ngFor="let childrenItem of menuItem.subCategoryList"
                  [class.sm-item-selected]="sideMenuService.selectedSubMenu === childrenItem">
                <!-- Link -->
                <a class="has-submenu sm-item ml24 colorHoverPrimary"
                   (click)="sideMenuService.onSelectMenu(menuItem, childrenItem)" href="javascript:void(0)">
                  {{getCategoryLabel(childrenItem) }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>

