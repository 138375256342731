import {Component, OnDestroy, OnInit} from '@angular/core';
import {HomeSlider} from '../../../../../data/slider';
import {CollectionApiService} from '../api/collection-api.service';
import {lastValueFrom} from 'rxjs';
import {SideMenuService} from '../../../../side-menu/service/side-menu.service';
import * as _ from "lodash";
import {ClientSettingApiService} from "../../../../../service/client-setting-api-service/client-setting-api.service";
import {firstValueFrom} from "rxjs";
import {NumberOfItemPerRowService} from "../../../../../service/number-of-item-per-row-service/number-of-item-per-row.service";

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit, OnDestroy {
  public categoryList: any;
  public categoryItemList: any;
  public trendingItemList: any;
  
  public selectedCategory: any;
  public selectedSubCategory: any;
  public selectedCategoryId: any;
  
  public HomeSliderConfig: any = HomeSlider;
  public carousels: any;
  public shopFeature: any;

  public pagination: {
    page: number,
    limit: number, 
  }
  public isNext: boolean;

  public isLoaded: boolean;
  public loadingProduct: boolean;
  public stopLoadMore: boolean;

  public eMenuSelectSub: any;

  public announcement: string;
  
  constructor(
    public sideMenuService: SideMenuService,
    private collectionApiService: CollectionApiService,
    private clientSettingApiService: ClientSettingApiService,
    public numberOfItemPerRowService: NumberOfItemPerRowService,
  ) {
    this.sideMenuService.isHomeScreen = true;
    
    this.pagination = {
      page: 1,
      limit: 48
    }
    this.eMenuSelectSub = this.sideMenuService.eMenuSelect.subscribe(() => {
      this.selectedCategory = this.sideMenuService.selectedMenu;
      this.selectedSubCategory = this.sideMenuService.selectedSubMenu;
      this.getCategoryProduct();
      const element = document.getElementById('categoryScrollId');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    })

    this.announcement = '';
    
    this.onScroll = _.throttle(this.onScroll, 300);
  }
  
  async ngOnInit() {
    await this.getProductList();
    this.getShopFeature();
    await this.getCarousel();
    await  this.getAnnouncement();
    await this.getTrendingItem();
    this.isLoaded = true;
  }
  
  ngOnDestroy() {
    this.eMenuSelectSub.unsubscribe();
  }

  async getCarousel(): Promise<void> {
    try {
      this.carousels = await lastValueFrom(this.collectionApiService.apiGetHomeCarousel());
    } catch (e) {
      console.error('get carousel failed', e);
    }
  }
  
  async getTrendingItem(): Promise<void> {
    try {
      this.trendingItemList = await lastValueFrom(this.collectionApiService.apiGetHomeTrending());
    } catch (e) {
      console.error('get trending item failed', e);
    }
  }
  
  async getProductList(): Promise<void> {
    try {
      if (!this.selectedCategoryId) { this.selectedCategoryId = 'ALL'; }
      const next = (this.pagination.page - 1) * this.pagination.limit;
      const response = await lastValueFrom(this.collectionApiService.apiGetHomeItem(next, this.pagination.limit, this.selectedCategoryId));

      if (!this.categoryList) {
        this.categoryList = response.categoryList;
      }

      if (this.pagination.page === 1) {
        this.categoryItemList = []; 
      }

      for (const category of response.list) {
        const categoryItem = this.categoryItemList.find(categoryItem => categoryItem._id === category._id);
        if (categoryItem && categoryItem.itemList) {
          categoryItem.itemList = categoryItem.itemList.concat(category.itemList);
        } else {
          this.categoryItemList.push(category);
        }
      }

      this.isNext = response.next !== null;
    } catch (e) {
      console.error('get latest product failed!', e);
    }
  }

  async getCategoryProduct(): Promise<void> {
    // this.loadingProduct = true;
    //
    // const dom = document.getElementById('v-pills-tabContent');
    // dom.scrollTo({top: 0});

    this.pagination.page = 1;
    
    this.selectedCategoryId = this.selectedSubCategory ? this.selectedSubCategory._id : this.selectedCategory._id;
    await this.getProductList();
    this.loadingProduct = false;
  }

  async onScroll(e) {
    const dom = e.target;
    if (dom.scrollHeight - (dom.scrollTop + dom.offsetHeight) < 800) {
      if (this.isNext && !this.loadingProduct && !this.stopLoadMore) {
        this.pagination.page += 1;
        await this.getProductList();
        this.stopLoadMore = true;
        setTimeout(() => {
          this.stopLoadMore = false;
        }, 1000);
      }
    }
  }

  private async getAnnouncement(): Promise<void> {
    try {
      const clientSetting = await firstValueFrom(this.clientSettingApiService.getClientSetting());
      this.announcement = clientSetting.announcement;
    }catch(e){
      console.error("fail to get client setting", e);
    }
  }

  public isAnnouncementIsEmpty(): boolean{
    return _.isEmpty(this.announcement);
  }

  private async getShopFeature(): Promise<void> {
    try {
      this.shopFeature = await firstValueFrom(this.collectionApiService.apiGetShopFeature());
    }catch(e){
      console.error("fail to get shop feature", e);
    }
  }
}
