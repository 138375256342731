import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {Product} from '../../../../../model/product/product';
import {NewProductSlider} from '../../../../../data/slider';
import {ProductService} from "../../../../../service/product-service/product.service";
import {UserService} from "../../../../../native/user/service/user.service";


@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements AfterViewInit{
  @Input() title: string = 'New Product'; // Default
  @Input() products : Product[] = [];
  @Output() eProductDetail: EventEmitter<any>;

  public newProductSliderConfig: any = NewProductSlider;

  constructor(
      public productService: ProductService,
      public userService: UserService
  ) {
    this.eProductDetail = new EventEmitter<any>();
  }

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 1000);
  }
  onGotoDetail(){
    this.eProductDetail.emit();
  }

  getPrice(product): number{
    return product.itemSupplierList.filter(supplier => supplier.isDefault).map(supplier => supplier.supplierPrice)[0] || 0;
  }


}
