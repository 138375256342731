import {Component, OnInit} from '@angular/core';
import {MostOrderedProductApiService} from "../api/most-ordered-product-api.service";
import {lastValueFrom} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-most-ordered-product',
  templateUrl: './most-ordered-product.component.html',
  styleUrls: ['./most-ordered-product.component.scss']
})
export class MostOrderedProductComponent implements OnInit{

  public isLoading: boolean;
  public productList: any[];
  public next;
  constructor(
      private mostOrderedProductApiService: MostOrderedProductApiService,
      private router: Router
  ){
    this.productList = [];
  }
  ngOnInit(): void {
    this.getMostOrderedProduct();
  }

  async getMostOrderedProduct(): Promise<void>{
    this.isLoading = true;
    try {
      const {list, next} = await lastValueFrom(this.mostOrderedProductApiService.apiGetMostOrderItem());
      this.productList = list;
      this.next = next;
    } catch (e) {
      console.error('get most ordered product failed!', e);
    }
    this.isLoading = false;
  }

  async getMoreMostOrderedProduct(): Promise<void>{
    if (!this.next) {
      return;
    }
    try {
      const {list, next} = await lastValueFrom(this.mostOrderedProductApiService.apiGetMostOrderItem(this.next));
      this.productList = this.productList.concat(list);
      this.next = next;
    } catch (e) {
      console.error('get more most ordered product failed!', e);
    }
  }
  onProductDetail(product): void{
    this.router.navigate(['/home/shop/product/', product?._id]);
  }
}
