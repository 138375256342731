import { Injectable } from '@angular/core';
import {BaseService} from "../../../../service/base-service/base.service";
import {TranslatorService} from "../../../../service/translator-service/translator.service";

@Injectable({
  providedIn: 'root'
})
export class CheckoutApiService {

  constructor(
      private baseService:BaseService,
      private translateService: TranslatorService
  ) { }

  // place order || checkout
  placeOrder(data){
    const language = this.translateService.language.toString();
    return this.baseService.post(  `/v1/user/place/order?_locale=${language}`,data);
  }
}
