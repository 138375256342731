<div class="h-no-header scrollY">
    <!--screen info-->
    <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
    <!--section start-->
    <section class="section-b-space">
        <div class="container">
            <div class="borderDividerY py24">
                <div class="fxRow fxRowCenter fxColumnCenter">
                    <div class="font16 fxRow fxRowCenter mb12 mr12">{{'check_out_delivery_date_label'| translate}}:</div>
                    <div class="fxColumn">
                        <div class="w250 h50 fxRow fxRowCenter fxColumnCenter p12 my6 border borderRadius">
                            <div  *ngIf="!deliveredAt" class="font16 fontBold">{{'check_out_asap_label' | translate}}</div>
                            <div  *ngIf="deliveredAt" class="font16 fontBold">{{deliveredAt| date: 'dd/MM/yyyy hh:mm a'}}</div>
                        </div>
                        <input id="deliveredAt" style="visibility: hidden; height: 0; width: 0; "
                               type="datetime-local" [(ngModel)]="deliveredAt" [min]="getFormatDateTimeNow()">
                    </div>
                    <button  class="btn btn-solid ml12 w166" (click)="onChangeDate()">
                        {{(deliveredAt ? 'check_out_change_button' : 'check_out_choose_date_button')| translate}}
                    </button>
                    <button class="btn btn-solid ml12 w166" [class.hidden]="!deliveredAt"  (click)="onResetDeliveryDate()">
                        {{'check_out_reset_date_button'| translate}}
                    </button>
                </div>
            </div>

            <section class="section-b-space">
                <div class="container positionRelative">
                    <div class="row">
                        <div class="col-lg-6 maxHeight350">
                            <div class="product-order fullHeight scrollY">
                                <div *ngIf ="!basketService.basketItemList[0]" class="fxColumn fxColumnCenter"
                                     [class.fxRowCenter]="!basketService.basketItemList[0]" >
                                    <!--empty basket-->
                                    <div  class="colorGray font20 fontMedium py12 noSelect">
                                        {{'basket_empty_basket'| translate}}</div>
                                </div>
                                <div *ngIf="basketService.basketItemList[0]" class="fadeIn">
                                    <div class="table-responsive-xl">
                                        <table class="table cart-table verticalAlignCenter">
                                            <thead>
                                            <tr class="positionSticky top0 zIndex40">
                                                <th></th>
                                                <th scope="col">
                                                    {{'check_out_table_header_product_name'| translate}}</th>
                                                <th scope="col" *ngIf="userService.isAllowViewPrice"
                                                    class="textCenter">{{'check_out_table_header_price'| translate}}</th>
                                                <th scope="col" class="textCenter">
                                                    {{'check_out_table_header_packing'| translate}}</th>
                                                <th scope="col" class="textCenter">
                                                    {{'check_out_table_header_quantity'| translate}}</th>
                                                <th scope="col" class="textCenter">
                                                    {{'check_out_table_header_action'| translate}}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr *ngFor="let basketItem of basketService.basketItemList" class="position-relative">
                                                <td class="textCenter">
                                                    <app-product-avatar [src]="basketItem?.item?.thumbnail" [iconSize]= "30"
                                                                        [style]="{width: '50px', height: '50px'}">
                                                    </app-product-avatar>
                                                </td>
                                                <td>
                                                    {{basketItem?.item?.name}}
                                                </td>
                                                <td *ngIf="userService.isAllowViewPrice" class="textCenter">
                                                    <div class="fontBold font16">
                                                        ${{basketItem.price | number:'1.2-2'}}</div>
                                                </td>
                                                <td class="textCenter">
                                                    <div *ngIf="!applicationService.isEnableChangePacking">{{basketItem?.item.packing}}{{basketItem?.item.unitOfMeasurement}}</div>
                                                    <input *ngIf="applicationService.isEnableChangePacking"  type="text" class="form-control h30 w60"
                                                           [(ngModel)]="basketItem.packing"/>
                                                </td>
                                                <td class="textCenter">
                                                    <div class="qty-box w128">
                                                        <div class="input-group">
                                                  <span>
                                                    <button type="button" class="btn quantity-left-minus"
                                                            data-type="minus"
                                                            (click)="basketService.onChangeQty(-1, basketItem)">
                                                       <i class="ti-angle-left"></i>
                                                    </button>
                                                  </span>
                                                            <input type="text" name="quantity" class="form-control
                                                     h30 w50 bg-transparent" [(ngModel)]="basketItem.qty" disabled/>
                                                            <span>
                                                    <button type="button" class="btn quantity-right-plus"
                                                            data-type="plus"
                                                            (click)="basketService.onChangeQty(1, basketItem)">
                                                    <i class="ti-angle-right"></i>
                                                    </button>
                                                  </span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="textCenter">
                                                    <div class="fxRow fxRowCenter fxColumnCenter cursorPointer"
                                                         (click)="basketService.onRemoveItemInBasket(basketItem)">
                                                        <svg-icon src="./assets/image/icon/shop/cart/delete-filled-icon.svg"
                                                                  class="size20 colorGray"></svg-icon>
                                                    </div>
                                                </td>
                                                <app-loader-overlay *ngIf="basketItem.isDeleting"></app-loader-overlay>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 maxHeight305" >
                            <div class="fontBold colorPrimary ml24 font16 mt6 mb12">
                                {{'check_out_choose_your_address_label'| translate}}</div>
                            <div class="order-success-sec fullHeight scrollY">
                                <!--empty address-->
                                <div *ngIf ="!orderAddressList[0]" class="colorGray font20 fontMedium py12 noSelect
                             fxRow fxRowCenter mt36">
                                    {{'check_out_no_address_label'| translate}}</div>
                                <!--address list-->
                                <div *ngIf ="orderAddressList[0]">
                                    <div class="pr12 fadeIn">
                                        <div *ngFor="let address of orderAddressList" class="fxRow fxColumnCenter
                                                p12 my6 borderNone borderRadius cursorPointer"
                                             [class.borderPrimary]="address._id === selectedOrderAddress._id"
                                             (click)="onSelectOrderAddress(address)">
                                            <div *ngIf="address._id !== selectedOrderAddress._id" class="borderCircle
                                         border size20"></div>
                                            <div *ngIf="address._id === selectedOrderAddress._id"
                                                 class="outer-custom-radio-container size20">
                                                <div class="inner-custom-radio-container size10"></div>
                                            </div>
                                            <app-product-avatar class="px12" [src]="address.photoUrl" [iconSize]= "50"
                                                                [style]="{width: '70px', height: '70px'}"></app-product-avatar>
                                            <div class="fxColumn">
                                                <div class="fontBold">
                                                    {{address.name}}
                                                </div>
                                                <div class="colorGray">
                                                    {{address.address}}
                                                </div>
                                                <div *ngIf="address?.contactList[0]" class="colorGray">
                                                    {{address.contactList[0].countryCode}} {{address.contactList[0].phone}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <app-loader-overlay *ngIf="loading"></app-loader-overlay>
                </div>
            </section>
            <div class="fxRow fxRowSpaceBetween" [class.mt24]="orderAddressList.length >=3">
                <button  class="btn btn-solid" (click)="onContinueShopping()">
                    {{'check_out_continue_shopping_button_title'| translate}}
                </button>
                <button class="btn btn-solid" (click)="onCheckOut()" [disabled]="!selectedOrderAddress">
                    <span >{{'check_out_check_out_button_title'| translate}}</span>
                </button>
            </div>
        </div>

    </section>
    <!--section end-->
    <app-footer></app-footer>
</div>






