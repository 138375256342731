import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ProductDetailApiService
} from '../../component/shop/product-detail/product-detail/api/product-detail-api.service';
import {Product} from '../../model/product/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public Products

  constructor(
    private http: HttpClient,
    private productDetailApi: ProductDetailApiService,
  ) { }

  public getProductById(productId: string): Observable<Product> {
    return this.productDetailApi.apiGetProductDetailById(productId);
  }
  
  public getLatestProduct(limit = 10): Observable<Product[]> {
    return this.productDetailApi.apiGetLatestProduct(limit);
  }
}
