import * as _ from 'lodash';

export class CommonObject {
  currentUser;

  constructor(dataJSON?) {
    if (!dataJSON && _.isEmpty(dataJSON)) { return; }
    const cloneDataJSON = _.cloneDeep(dataJSON);
    Object.assign(this, cloneDataJSON);
  }

  isEmpty(): boolean {
    const keyWithValue = Object.keys(this).filter(key => this[key]);
    return _.isEmpty(keyWithValue);
  }

  getCurrentUser(): any {
    try {
      this.currentUser = JSON.parse(localStorage.getItem('user'));
    } catch (e) {
      this.currentUser = null;
    }
  }
}
