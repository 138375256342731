import { Component } from '@angular/core';
import {DialogService} from "../service/dialog.service";
import {Dialog} from "../model/dialog";

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent {

  constructor(
    public dialogService: DialogService
  ) { }

  ngOnInit(): void {
  }

  onClickDialog(e): void { e.stopPropagation(); }
  onClose(e: any, selectedDialog: Dialog, dialogDom: any): void {
    if (e.target.contains(dialogDom)) {
      selectedDialog.close();
    }
  }
}
