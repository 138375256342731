<header class="fadeIn sticky border-bottom positionFixed top0 zIndex40 w100vw">
  <div *ngIf="!isOnNoHeaderActionScreen" class="mobile-fix-option"></div>
  <div class="top-header">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>{{ 'header_welcome_text' | translate }}</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>{{ 'header_call_us' | translate }}: 123 - 456 - 7890</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left col-6">
            <a *ngIf="!isOnNoHeaderActionScreen" href="javascript:void(0)"
               (click)="sideMenuService.menuToggle = true" class="d-xl-none category-mobile-button pe-3">
                <i class="ti-menu font24"></i></a>
            <div class="brand-logo">
              <a routerLink="/home" id="headerlogo">
                <img src="./assets/image/logo/app-logo/icon.png" class="img-fluid w200 cursorPointer" alt="logo">
              </a>
            </div>
            <div *ngIf="!isOnNoHeaderActionScreen" class="col-md-6 col-lg-12" style="margin-left: 135px">
              <form class="form_search rounded-1">
                <input #searchInput  id="query search-autocomplete"
                       name="search-product"
                       [placeholder]="'header_search_hint'| translate" class="nav-search nav-search-field"
                       [(ngModel)]="searchWord"
                       (keydown.enter)="onSearch()"
                       (blur)="onSearch()">
                <button (click)="onSearch()" type="submit" name="nav-submit-button" class="btn-search">
                  <i class="ti-search"></i>
                </button>
              </form>
            </div>
          </div>
          <div *ngIf="!isOnNoHeaderActionScreen" class="menu-right pull-right">
            <div class="icon-nav">
              <ul>
                <li class="onhover-div d-xl-none mobile-search search-widgets mr24" id="search-widgets">
                  <div><i class="ti-search font24" (click)="openSearch = !openSearch"></i></div>
                  <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': openSearch ? 'block' : 'none' }">
                    <div>
                      <span class="closebtn" title="Close Overlay" (click)="openSearch = !openSearch">×</span>
                      <div class="overlay-content">
                        <div class="container">
                          <div class="row">
                            <div class="col-xl-12">
                              <form>
                                <div class="form-group positionRelative">
                                  <input type="text" class="form-control nav-search nav-search-field"
                                         name="search-product"
                                         [(ngModel)]="searchWord"
                                         [placeholder]="'header_search_hint'| translate"
                                         (keydown.enter)="onSearch()"
                                         (blur)="onSearch()"
                                  />
                                  <div><i class="ti-search font20 positionAbsolute"
                                          style="right: 12px; top: 10px;" (click)="onSearch()"></i></div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li *ngIf="isShowChangeLanguage"
                    class="onhover-div mobile-language mr24">
                  <div><svg-icon class="size24 cursorPointer" src="./assets/image/icon/header/globe-icon.svg"></svg-icon></div>
                  <div class="show-div language">
                    <ul>
                      <li>
                        <a data-lng="es">
                          <div class="font16 backgroundPrimaryHover px24 py6" (click)="onChangeLanguage('en')">{{'English'| translate}}</div>
                          <div class="font16 backgroundPrimaryHover px24 py6" (click)="onChangeLanguage('km')">{{'ភាសាខ្មែរ'| translate}}</div>
                          <div class="font16 backgroundPrimaryHover px24 py6" (click)="onChangeLanguage('zh')">{{'中國語'| translate}}</div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="onhover-div mobile-cart mr24">
                  <div *ngIf="!isOnNoHeaderActionScreen" class="fxRow">
                    <div class="badge-container">
                      <svg-icon class="size24 cursorPointer" src="./assets/image/icon/header/shopping-cart-icon.svg"
                                (click)="onShoppingCart()"></svg-icon>
                      <span *ngIf="basketService.basketItemList.length > 0" class="badge">{{ basketService.basketItemList.length }}</span>
                    </div>
                  </div>
                </li>
                <li class="onhover-div mobile-profile">
                  <div> <app-avatar class="cursorPointer" [user]="userService.getUser()" [size]="28"></app-avatar></div>
                  <div class="show-div profile">
                    <ul>
                      <li [routerLink]="['/home/my-account']" class="py-3 px-4 colorHoverPrimary">
                        <a data-lng="es">
                          <div class="fxRow fxRowCenter fxColumnCenter">
                            <i class="fa fa-user font18" aria-hidden="true"></i>
                            <div class="font16 ml12" style="white-space: nowrap">{{ 'header_my_account_label' | translate }}</div>
                          </div>
                        </a>
                      </li>
                      <li [routerLink]="['/home/support']" class="py-3 px-4 colorHoverPrimary">
                        <a data-lng="es">
                          <div class="fxRow fxRowCenter fxColumnCenter">
                            <i class="fa fa-headphones font18" aria-hidden="true"></i>
                            <div class="font16 ml12" style="white-space: nowrap">{{ 'header_support_label' | translate }}</div>
                          </div>
                        </a>
                      </li>
                      <li (click)="onLogout()" class="py-3 px-4 colorHoverPrimary">
                        <a data-lng="es">
                          <div class="fxRow fxRowCenter fxColumnCenter">
                            <i class="fa fa-sign-out font18" aria-hidden="true"></i>
                            <div class="font16 ml12">{{'header_profile_logout_label'| translate}}</div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- shop cart right nav-->
<div class="shop-my-cart-overlay" (click)="onDebounceCloseShoppingCart()" *ngIf="isShowMyCart"></div>
<app-basket *ngIf="isShowMyCart" class="shop-my-cart" (eClose)="onDebounceCloseShoppingCart()"
            [cartItemList]="basketService.basketItemList"
            [class.shop-close-my-cart]="isCloseMyCart"></app-basket>

