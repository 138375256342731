import { Injectable } from '@angular/core';
import {BaseService} from "../../../service/base-service/base.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TicketApiService {

  constructor(
      private baseService: BaseService
  ) {

  }
  requestTicket(data: any): Observable<any>{
    return this.baseService.post('/v1/user/request/support', data);
  }

  getTicketList(next: number, limit: number): Observable<any>{
    return this.baseService.get(`/v1/user/get/support/list/${next}/${limit}`);
  }
  sendTicketMessage(supportId, data: any): Observable<any>{
    return this.baseService.post(`/v1/user/send/support/message/${supportId}`, data);
  }

  getTicketMessageList(supportId, next: number, limit: number): Observable<any>{
    return this.baseService.get(`/v1/user/get/support/message/list/${supportId}/${next}/${limit}`);
  }
}
