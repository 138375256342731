import {EventEmitter, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom} from 'rxjs';
import {LocalStorageKey} from "../local-storage-service/local-storage.service";
import {UserService} from "../../native/user/service/user.service";
import {ToastrService} from "ngx-toastr";

class Language {
  static English = 'en';
  static Khmer = 'km';
  static Chinese = 'zh';
}

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {

  private isInit: boolean;
  public language = Language.English;
  public changeLanguageSignal: EventEmitter<Language> = new EventEmitter<Language>();

  constructor(
      private translateService: TranslateService,
      private userService: UserService,
      private toastService: ToastrService) {
   this.init();
  }

  init(): void {
    //
    // if (this.userService.user) {
    //   const language = this.userService.user.language || Language.English;
    //   this.changeLanguage(language);
    // } else {
      const language = localStorage.getItem(LocalStorageKey.language) || Language.English;
      this.changeLanguage(language,true);
   // }

    if (this.isInit) { return; }
    this.translateService.onLangChange.subscribe(e => { this.changeLanguageSignal.emit(e); });

    this.isInit = true;
  }
  
  async translate(key, param = {}): Promise<any> {

    return await lastValueFrom(this.translateService.get(key, param));
  }

  async changeLanguage(language: string,isHideInfo: boolean = false) {
    this.language = language;
    this.translateService.use(this.language);
    localStorage.setItem(LocalStorageKey.language, this.language);

    //Todo update user here(User language)
    if(!isHideInfo){
      const message = await this.translate('setting_change_language_success_info');
      this.toastService.success(message,null, {positionClass: 'toast-top-left'});
    }
  }
}
