<div class="fadeIn" *ngIf="isLoaded">
  <!-- product listing section start -->
  <div class="collection-wrapper">
    <div class="fxRow">
      <div class="col-xl-auto">
        <app-side-menu [items]="categoryList"></app-side-menu>
      </div>

      <div (scroll)="onScroll($event)"
        class="col-xl-auto shop-main h-no-header tab-content"
        id="v-pills-tabContent"
      >
        <div class="tab-pane fade show active px-3" id="grocery">
          <app-kpi-expense-by-outlet *ngIf="shopFeature?.expenseOfOutlet?.isEnabled"></app-kpi-expense-by-outlet>
          <app-most-ordered-product *ngIf="shopFeature?.topOrderProduct?.isEnabled"></app-most-ordered-product>
<!--          <div *ngIf="!selectedCategory || selectedCategory._id === 'ALL'" class="mt24 rounded-2 noScroll">-->
<!--            <owl-carousel-o [options]="HomeSliderConfig" class="home-slider">-->
<!--              <ng-container *ngFor="let carousel of carousels">-->
<!--                <ng-template carouselSlide>-->
<!--                  <div class="ratio" style="aspect-ratio: 16 / 4.5">-->
<!--                    <img [src]="carousel.thumbnail" [lazyLoad]="carousel.preview" [defaultImage]="carousel.thumbnail"-->
<!--                         class="img-fluid object-fit-cover owl-lazy" alt="carousel">-->
<!--                  </div>-->
<!--                </ng-template>-->
<!--              </ng-container>-->
<!--            </owl-carousel-o>-->
<!--          </div>-->
          <div *ngIf="!isAnnouncementIsEmpty()" class="announcement fxRow fxColumnCenter backgroundPrimaryAlpha borderRadius">
            <i class="fa fa-bullhorn colorPrimary" aria-hidden="true"></i>
            <div class="announcement-text colorPrimary">{{this.announcement}}</div>
          </div>
          <section *ngIf="(!selectedCategory || selectedCategory._id === 'ALL') && trendingItemList &&
          trendingItemList.list && trendingItemList.list[0]">
            <div class="title8 fxRow fxRowSpaceBetween fxColumnCenter">
              <h2>{{ trendingItemList?.title }}</h2>
              <div *ngIf="numberOfItemPerRowService.ifShowGridIconContainer()" class="borderCircle foreground p12">
                <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid4Icon()"
                          (click)="numberOfItemPerRowService.onChangeNumberOfRow(4)"
                          class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid4-icon.svg"
                ></svg-icon>
                <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid3Icon()"
                          (click)="numberOfItemPerRowService.onChangeNumberOfRow(3)"
                          class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid3-icon.svg"
                ></svg-icon>
                <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid6Icon()"
                          (click)="numberOfItemPerRowService.onChangeNumberOfRow(6)"
                          class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid6-icon.svg"
                ></svg-icon>
              </div>
            </div>
            <div class="row g-sm-3 g-3">
              <app-product-box
                *ngFor="let product of trendingItemList.list"
                [product]="product"
                [loader]="loadingProduct"
                [class.col-6] = "numberOfItemPerRowService.numberOfItemPerRow < 3"
                [class.w33p] = "numberOfItemPerRowService.numberOfItemPerRow == 3"
                [class.w25p] = "numberOfItemPerRowService.numberOfItemPerRow == 4"
                [class.w16p]= "numberOfItemPerRowService.numberOfItemPerRow == 6"
              ></app-product-box>
            </div>
          </section>
          <div id="categoryScrollId"></div>
          <ng-container *ngFor="let categoryItem of categoryItemList; let i = index;" >
            <section *ngIf="!loadingProduct && categoryItem.itemList && categoryItem.itemList[0]">
              <div class="title8">
                <div class="fxRow fxRowSpaceBetween fxColumnCenter">
                  <div class="fxColumn">
                    <h2>{{ categoryItem?.label || '-' }}</h2>
                    <p *ngIf="categoryItem.description">{{ categoryItem.description }}</p>
                  </div>
                  <div *ngIf="!trendingItemList.list[0] && i === 0 && numberOfItemPerRowService.ifShowGridIconContainer()"
                       class="borderCircle foreground p12 cursorPointer">
                    <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid4Icon()"
                              (click)="numberOfItemPerRowService.onChangeNumberOfRow(4)"
                              class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid4-icon.svg"
                    ></svg-icon>
                    <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid3Icon()"
                              (click)="numberOfItemPerRowService.onChangeNumberOfRow(3)"
                              class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid3-icon.svg"
                    ></svg-icon>
                    <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid6Icon()"
                              (click)="numberOfItemPerRowService.onChangeNumberOfRow(6)"
                              class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid6-icon.svg"
                    ></svg-icon>
                  </div>
                </div>
              </div>
              <div class="row g-sm-4 g-3">
                <app-product-box
                  *ngFor="let product of categoryItem.itemList"
                  [product]="product"
                  [loader]="loadingProduct"
                  [class.col-6] = "numberOfItemPerRowService.numberOfItemPerRow < 3"
                  [class.w33p] = "numberOfItemPerRowService.numberOfItemPerRow == 3"
                  [class.w25p] = "numberOfItemPerRowService.numberOfItemPerRow == 4"
                  [class.w16p]= "numberOfItemPerRowService.numberOfItemPerRow == 6"
                ></app-product-box>
              </div>
            </section>
          </ng-container>

        </div>

        <section>
          <div class="product-infinitescroll">
            <div class="theme-paggination-block">
              <div class="container-fluid p-0">
                <div class="fxRow fxRowCenter">
                  <div class="text-center col-sm-12">
                    <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                      <img *ngIf="isNext || loadingProduct" class="loading-icon" src="./assets/image/img/product/loader.gif" >
                      <div *ngIf="!isNext && !loadingProduct" class="title8">
                        <p>{{ 'home_collection_no_more_product' | translate }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <app-footer></app-footer>
        </section>
      </div>
    </div>
  </div>
  <!-- product listing section end -->
</div>

<div *ngIf="!isLoaded">
  <!-- product listing section start -->
  <div class="collection-wrapper">
      <div class="row">
        <div class="col-xl-auto d-none d-xl-block p-0">
          <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'width': '320px', 'border-radius': '0', height: '100vh' }"></ngx-skeleton-loader>
        </div>

        <div class="col px-3">

          <div class="pt24">
            <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '10', height: '300px' }"></ngx-skeleton-loader>
          </div>

          <section>
            <div>
              <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '20px', 'width': '150px' }"></ngx-skeleton-loader>
            </div>
            
            <div class="mt6">
              <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '15px', 'width': '250px' }"></ngx-skeleton-loader>
            </div>

            <div class="row g-sm-4 g-3">
              <app-skeleton-product-box
                *ngFor="let product of [].constructor(10)"
                class="col-xxl-2 col-xl-3 col-md-4 col-6"
              ></app-skeleton-product-box>
            </div>

          </section>
        </div>

      </div>
  </div>
  <!-- product listing section end -->
</div>


