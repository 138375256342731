import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from 'rxjs';
import {TicketService} from "../../component/ticket/service/ticket.service";
import {SocketService} from "../socket-service/socket.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{
  constructor(
      private ticketService: TicketService,
      private socketService: SocketService,
      private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return new Observable(observer => {
          const subscription = next.handle(request).subscribe(
              (event) => { observer.next(event); },

              (err: any) => {
                  if (err instanceof HttpErrorResponse) {
                      // token expired
                      if ([401, 403].includes(err.status)) {

                          this.router.navigate(['']);
                          localStorage.removeItem('user');
                          localStorage.removeItem('token');
                          localStorage.removeItem('cartItems');
                          this.ticketService.destroy();
                          this.socketService.destroySocket();
                          observer.complete();
                      }
                  }
                  observer.error(err);
              },

              () => { observer.complete(); }
          );

          return () => { subscription.unsubscribe(); };
      });
  }
}
