import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./component/login/component/login.component";
import {HomeComponent} from "./component/home/component/home.component";
import {ProductDetailComponent} from './component/shop/product-detail/product-detail/component/product-detail.component';
import {CollectionComponent} from './component/shop/collection/collection/component/collection.component';
import {CheckoutComponent} from "./component/shop/checkout/component/checkout.component";
import {CheckoutSuccessComponent} from "./component/shop/checkout-success/component/checkout-success.component";
import {OrderConfirmationComponent} from './component/supplier/order-confirmation/component/order-confirmation.component';
import {ErrorPageComponent} from './component/error-page/component/error-page.component';
import {SearchProductComponent} from "./component/search-product/component/search-product.component";
import {PrivacyPolicyComponent} from "./component/privacy-policy/component/privacy-policy.component";
import {TermAndConditionComponent} from "./component/term-and-condition/component/term-and-condition.component";
import {MyAccountComponent} from './component/my-account/component/my-account.component';
import {TicketComponent} from "./component/ticket/component/ticket.component";
import {TicketDetailComponent} from "./component/ticket/ticket-detail/component/ticket-detail.component";

const routes: Routes = [
  {path: '', component: LoginComponent, title: 'login'},
  {path: 'order-confirmation/:orderId', component: OrderConfirmationComponent},
  {path: 'home', component: HomeComponent, children: [
      {path: '', redirectTo: 'shop', pathMatch: 'full'},
      {path: 'my-account', component: MyAccountComponent},
      {path: 'search', component: SearchProductComponent},
      {path: 'support', component: TicketComponent},
      {path: 'support/:ticketNumber', component: TicketDetailComponent},
      {path: 'shop', children: [
          {path: '', component: CollectionComponent},
          {path: 'cart', component: CheckoutComponent},
          {path: 'checkout', component: CheckoutSuccessComponent},
          {path: 'product/:productId', component: ProductDetailComponent}
      ]}
  ]},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'term-and-condition', component: TermAndConditionComponent},
  {path: '404', component: ErrorPageComponent},
  {path: '**', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
