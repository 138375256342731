import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from '../../../../native/dialog/model/dialog';
import {UserService} from '../../../../native/user/service/user.service';
import {ProductService} from '../../../../service/product-service/product.service';

@Component({
  selector: 'app-order-detail-dialog',
  templateUrl: './order-detail-dialog.component.html',
  styleUrls: ['./order-detail-dialog.component.scss']
})
// dialog=orderDetailDialog
export class OrderDetailDialogComponent implements OnInit {
  @Input() dialog: Dialog;
  
  public order: any;
  public address: any;
  
  constructor(
    public userService: UserService,
    public productService: ProductService
  ) {
  }
  
  ngOnInit() {
    this.order = this.dialog.options.data;
    this.address = this.order.deliveryAddress;
  }


}
