import { Injectable } from '@angular/core';
import {BaseService} from '../../../service/base-service/base.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(
    private baseService: BaseService
  ) { }
  
  apiGetUserOrganization(): Observable<any> { return this.baseService.get('/v1/user/get/current/user/organization'); }
  apiGetUserAddressList(): Observable<any> { return this.baseService.get('/v1/user/get/user/addressList'); }
  apiGetMyOrder(next = 0, limit = 10): Observable<any> { return this.baseService.get(`/v1/user/get/order/history/${next}/${limit}`); }
  apiGetMyOrderCount(): Observable<any> { return this.baseService.get(`/v1/user/get/order/count`); }
  apiUpdatePassword(oldPassword: string, newPassword: string): Observable<any> { return this.baseService.put('/v1/user/change/password', {oldPassword, newPassword}); }
  apiUpdateCurrentUser(userData): Observable<any> { return this.baseService.put('/v1/user/update/currentUser', userData); }
}
