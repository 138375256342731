<div class="h500 borderRadius p12 backgroundDivider mt12">
    <div class="fullHeight fxColumn">
        <div class="title8">
            <h2>
            {{'home_expenses_of_outlet_title'| translate}}
            </h2>
        </div>
        <div class="fullHeight" [class.h0w0]="isLoading">
            <canvas id="kpiExpenseByOutlet"></canvas>
        </div>
        <div *ngIf="isLoading" class="fullHeight fxRow fxRowCenter fxColumnCenter">
            <img src="./assets/image/img/shop/collection/loader.gif" class="loading-icon img-fluid">
        </div>
    </div>

</div>
