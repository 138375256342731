import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Breadcrumb} from '../../../../native/breadcrumb/model/breadcrumb';
import {firstValueFrom} from "rxjs";
import {OrderAddress} from "../../../../model/order-address/order-address";
import {BasketApiService} from "../../basket/api/basket-api.service";
import {UserService} from '../../../../native/user/service/user.service';
import {ToastrService} from "ngx-toastr";
import {TranslatorService} from "../../../../service/translator-service/translator.service";
import {DialogService} from "../../../../native/dialog/service/dialog.service";
import {BasketService} from "../../basket/service/basket.service";
import {AddressApiService} from "../../../../service/address-api-service/address-api.service";
import {CheckoutApiService} from "../api/checkout-api.service";
import {ApplicationService} from "../../../../service/application-service/application.service";

@Component({
  selector: 'app-cart',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent implements  OnInit{

  public orderAddressList: OrderAddress[];
  public selectedOrderAddress: OrderAddress;
  public breadcrumb: Breadcrumb;
  public deliveredAt: string;
  public loading: boolean;
  
  constructor(
      private router:Router,
      private addressApiService: AddressApiService,
      private basketApiService: BasketApiService,
      public userService: UserService,
      private toasterService: ToastrService,
      private translatorService: TranslatorService,
      private dialogService: DialogService,
      public  basketService: BasketService,
      private checkoutApiService: CheckoutApiService,
      public applicationService: ApplicationService
  ){
  this.orderAddressList = [];
  this.breadcrumb = new Breadcrumb({
    title: 'CART',
    breadcrumbList: [
      {
        label: 'Cart'
      }
    ]
  });
}

  ngOnInit(): void {
    this.getOrderAddress();
    this.basketService.getBasketItem();
    this.basketService.emptyBasketEventEmitter.subscribe(
        () => {
          this.router.navigateByUrl('home');
        }
    );
  }

  // get order address
  getOrderAddress(){
    try {
      this.addressApiService.getAddress().subscribe((response) => {
        this.orderAddressList = response;
        this.selectedOrderAddress = this.orderAddressList[0];
      },(error)=>{
        console.error('Get Order Address fail!', error);
      });
    } catch(e){
      console.error('Get Order Address fail!', e);
    }
  }

  // select order address
  onSelectOrderAddress(address: OrderAddress): void{
    this.selectedOrderAddress  = address;
  }

  // continue shopping
  onContinueShopping(): void{
    this.router.navigateByUrl('home/shop');
  }

  // place order
   onCheckOut(): void{
    this.selectedOrderAddress.organizationId = this.userService.user.organizationId;
    const reqBody = {
      menuItems: this.basketService.basketItemList,
      deliveryAddress: this.selectedOrderAddress,
      deliveryInstruction: '',
      deliveredAt: this.deliveredAt ? new Date(this.deliveredAt) : null
    };

    this.loading = true;

    this.checkoutApiService.placeOrder(reqBody).subscribe(async (response) => {
      this.router.navigateByUrl('home/shop/checkout',{ state: response });
      const toasterText = await this.translatorService.translate('check_out_check_out_success_message');
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
      this.basketService.itemBasketQtyMap = {};
      this.basketService.getBasketItem();
      this.loading = false;
      }, async (error) => {
        if (error.error?.data?.basket?.basketItems) {
          for (const item of error.error?.data?.basket?.basketItems) {
            if (item.qty > item.item?.stock) {
              const reqBody = {
                qty: item.item?.stock,
                instruction: item.instruction,
                options: item.options
              };
              try {
                const status = this.basketApiService.updateItemInBasket(item._id, reqBody);
                await firstValueFrom(status);
              } catch (e) {
                console.error('Update Basket Item Qty Fail!', e);
              }
            }
          }
          this.dialogService.open('confirmDialog', {
            data: {
              description: error?.error?.message
            }
          });
          this.basketService.getBasketItem();
        } else {
          this.toasterService.error(error?.error?.message, null, {positionClass: 'toast-top-left'});
        }
        console.error('Place order Fail!', error);
        this.loading = false;
      }
    );
  }
  onChangeDate():void{
    if (!this.deliveredAt) {
      this.deliveredAt = this.getFormatDateTimeNow();
    }
    setTimeout(() => {
      (document.querySelector('#deliveredAt') as any).showPicker();
    }, 50);
  }
  onResetDeliveryDate(): void{
      this.deliveredAt = null;
  }
  getFormatDateTimeNow(): string {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Mon  th is zero-based
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    return`${year}-${month}-${day}T${hours}:${minutes}`;
  }
}
