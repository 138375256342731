import {Component, Input} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import * as _ from 'lodash';
import {TicketApiService} from "../../../component/ticket/api/ticket-api.service";
import {firstValueFrom} from "rxjs";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-add-ticket-dialog',
  templateUrl: './add-ticket-dialog.component.html',
  styleUrls: ['./add-ticket-dialog.component.scss']
})
export class AddTicketDialogComponent {
  @Input() dialog: Dialog;
  public isLoading: boolean;
  public error: any;
  public subjectInput;
  public messageInput;
  constructor(
      private ticketApiService: TicketApiService,
      private translatorService: TranslatorService,
      private toasterService: ToastrService,
  ){
    this.subjectInput = '';
    this.messageInput = '';
    this.error = {};
  }
  public async onAdd() : Promise<void> {
    if (!this.validateItem()) { return; }
    this.isLoading = true;
    try {
      const response =  await firstValueFrom(this.ticketApiService.requestTicket(
          {subject: this.subjectInput, message: this.messageInput}));
      this.isLoading = false;
      this.dialog.close(response);
      const toasterText = await this.translatorService.translate('ticket_request_ticket_success');
      this.toasterService.success(toasterText,null,{ positionClass: 'toast-top-left'});
    } catch(e) {
      this.isLoading = false;
      this.dialog.close();
      const toasterText = await this.translatorService.translate('ticket_request_ticket_fail');
      this.toasterService.error(toasterText,null,{ positionClass: 'toast-top-left'});
      console.error('fail to request model', e);
    }

  }

  public onInputChange(name: string): void {
    this.error[name] = false;
  }

  private validateItem(): boolean {
    this.error.subject = _.isEmpty(this.subjectInput.trim());
    this.error.message = _.isEmpty(this.messageInput.trim());
    for (const key in this.error) {
      if (this.error.hasOwnProperty(key)) {
        if (this.error[key]) {
          return false;
        }
      }
    }
    return true;
  }
}
