<div class="scrollY px-4 py-2">
    <div class="mb-4 row justify-content-between align-items-center top-sec">
        <h3 class="col-11">{{ 'ticket_add_new_dialog_title' | translate }}</h3>
        <i class="ti-close font20 col-1 cursorPointer" (click)="dialog.close()"></i>
    </div>
    <div class="mb-3">
        <label for="form-input-subject" class="form-label">{{ 'ticket_add_new_ticket_subject_label' | translate }}</label>
        <input id="form-input-subject"
               [(ngModel)]="subjectInput"
               [class.is-invalid]="error.subject"
               (input)="onInputChange('subject')"
               class="form-control"
               [placeholder]="'ticket_add_new_ticket_subject_hint' | translate"
        />
    </div>
    <div class="mb-4">
        <label for="form-input-message" class="form-label">{{ 'ticket_add_new_ticket_message_label' | translate }}</label>
        <textarea id="form-input-message"
               [(ngModel)]="messageInput"
               [class.is-invalid]="error.message"
               (input)="onInputChange('message')"
               class="form-control"
               style=" resize: none; height: 200px"
               rows="6"
               [placeholder]="'ticket_add_new_ticket_message_hint' | translate">
        </textarea>
    </div>

    <div class="positionRelative">
        <div class="fxRow fxRowEnd">
            <button class="btn btn-solid" (click)="onAdd()" [disabled]="isLoading">
                {{ 'ticket_add_new_dialog_submit_button_title' | translate }}
            </button>
        </div>
        <app-loader-overlay *ngIf="isLoading"></app-loader-overlay>
    </div>
</div>
