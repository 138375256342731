import {Component, Input, OnInit} from '@angular/core';
import {Dialog} from "../../../native/dialog/model/dialog";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {KpiExpenseByOutletApiService} from "../../../component/shop/collection/kpi-expense-by-outlet/api/kpi-expense-by-outlet-api.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-expense-list-dialog',
  templateUrl: './expense-list-dialog.component.html',
  styleUrls: ['./expense-list-dialog.component.scss']
})
export class ExpenseListDialogComponent  implements OnInit{
  @Input() dialog: Dialog;
  public next;
  public isLoading: boolean;
  public expenseList: any[];
  private query: {};

  constructor(
      private expenseByOutletApiService: KpiExpenseByOutletApiService,
      private dialogService: DialogService
  ){
    this.expenseList = [];
    this.query = {};
  }
  ngOnInit(): void {
    this.query = {
      year: this.dialog.options.data.year,
      month: this.dialog.options.data.month,
      addressId: this.dialog.options.data.outlet.id
    };
    this.getExpenseListByOutlet();
  }


  async getExpenseListByOutlet(): Promise<void>{
    this.isLoading = true;
    try {
      const {list, next} = await lastValueFrom(this.expenseByOutletApiService.apiGetKpiExpenseListByOutletAndMonthYear(this.query, 0));
      this.expenseList = list;
      this.next = next;
    } catch (e) {
      console.error('get expense list by outlet failed!', e);
    }
    this.isLoading = false;
  }

  async getMoreExpenseListByOutlet(): Promise<void>{
    if (!this.next) { return ; }
    try {
      const {list, next} = await lastValueFrom(this.expenseByOutletApiService.apiGetKpiExpenseListByOutletAndMonthYear(this.query, this.next));
      this.expenseList = this.expenseList.concat(list);
      this.next = next;
    } catch (e) {
      console.error('get more expense list by outlet failed!', e);
    }
  }
  onClose(): void{
    this.dialogService.close(this.dialog.name);
  }
}
