<!-- breadcrumb start -->
<div class="fadeIn breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="page-title">
          <div *ngIf="showBackButton" (click)="onBack()" class="fxRow fxColumnCenter gap-1 cursorPointer colorHoverPrimary">
            <span class="font18 ti-angle-left"></span>
            <h2>{{ 'breadcrumb_back_button' | translate }}</h2>
            <i *ngIf="showBackButton" class="ti-minus mx-2"></i>
            <h2>{{ breadcrumb?.title }}</h2>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/home">{{ 'breadcrumb_home' | translate }}</a></li>
            <li *ngFor="let breadcrumbLink of breadcrumb?.breadcrumbList" class="breadcrumb-item active" aria-current="page">
              <a *ngIf="breadcrumbLink.link" [routerLink]="breadcrumbLink.link">{{ breadcrumbLink.label }}</a>
              <span *ngIf="!breadcrumbLink.link">{{ breadcrumbLink.label }}</span>
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
