import { Injectable } from '@angular/core';
import {BaseService} from "../base-service/base.service";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ClientSettingApiService {

  constructor(private baseService: BaseService) { }

  getClientSetting(): Observable<any>{
    return this.baseService.get('/v1/user/get/clientSetting');
  }
}
