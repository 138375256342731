import { Injectable } from '@angular/core';
import {BaseService} from '../../../../../service/base-service/base.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CollectionApiService {

  constructor(
    private baseService: BaseService
  ) { }
  
  apiGetHomeItem(next = 0, limit = 24, selectedCategoryId = 'ALL'): Observable<any> {
    return this.baseService.get(`/v1/user/get/home/item/${next}/${limit}?selectedCategoryId=${selectedCategoryId}`);
  }
  apiGetHomeCarousel(): Observable<any> { return this.baseService.get(`/v1/user/get/home/carousel`); }
  apiGetHomeTrending(next = 0, limit = 24): Observable<any> { return this.baseService.get(`/v1/user/get/trending/item/${next}/${limit}`); }
  apiGetShopFeature(): Observable<any> { return this.baseService.get('/v2/shop/feature'); }
}
