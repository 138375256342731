<div class="h-no-header fadeIn scrollY" (scroll)="onScroll($event)">
    <app-breadcrumb [breadcrumb]="breadCrumb"></app-breadcrumb>
    <section class="section-b-space">
        <div class="container" (scroll)="onScroll($event)">
            <div class="fxRow fxRowEnd">
                <button class="btn btn-solid" (click)="onOpenAddTicketDialog()">
                    {{'ticket_add_new_ticket_button_title'| translate}}
                </button>
            </div>
            <div class="divider my24"></div>
            <div>
                <div *ngIf="isLoading && !(ticketList.length > 0)"class="fxRow fxRowCenter fxColumnCenter h200">
                    <app-loader-overlay *ngIf="isLoading"></app-loader-overlay>
                </div>
                <div *ngIf="!isLoading && !(ticketList.length > 0)" class="fxRow fxRowCenter fxColumnCenter h200">
                    <div class="font20 colorGray">{{'ticket_no_ticket_yet'| translate}}</div>
                </div>
                <div *ngIf="ticketList.length > 0" class="table-responsive-xl">
                    <table class="table cart-table verticalAlignCenter">
                        <thead>
                        <tr class="table-head">
                            <th scope="col">
                                {{'ticket_table_header_ticket_number' | translate}}</th>
                            <th scope="col">{{'ticket_table_header_subject' | translate}}</th>
                            <th scope="col">
                                {{'ticket_table_header_status' | translate}}</th>
                            <th scope="col">
                                {{'ticket_table_header_ticket_last_update' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let ticket of ticketList" class="position-relative cursorPointer ticket-row-data "
                            (click)="onGotoTicketDetail(ticket)">
                            <td class="">
                                #{{ticket.ticketNumber}}
                            </td>
                            <td>
                                <div class="subject">{{ticket.subject}}</div>
                            </td>
                            <td class="textCenter">
                                <div class=" h50 fxRow fxColumnCenter fxRowCenter">
                                    <div class="status-container"
                                         [class.status-on-hold] ="ticket.status === 'pending'"
                                         [class.status-in-progress] ="ticket.status === 'progressing'"
                                         [class.status-solved] ="ticket.status === 'closed'"
                                    >{{capitalizeFirstLetter(ticket.status)}}</div>
                                </div>
                            </td>
                            <td class="textCenter">
                                <div class="fxColumn fxRowCenter">
                                    {{ticket.updatedAt | date:'dd MMM, yyyy hh:mm a'}}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="isLoading && ticketList.length > 0"class="fxRow fxRowCenter fxColumnCenter h50">
                    <img class="loading-icon" src="./assets/image/img/product/loader.gif" >
                </div>
            </div>
        </div>
    </section>
    <section>
        <app-footer></app-footer>
    </section>
</div>

