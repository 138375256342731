import { Injectable } from '@angular/core';
import {BaseService} from '../../../../service/base-service/base.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderConfirmationApiService {

  constructor(
    private baseService: BaseService
  ) { }
  
  apiGetConfirmationOrderDetail(supplierContractId: any, chatId: any): Observable<any> {
    localStorage.removeItem('token');
    return this.baseService.get(`/v1/public/get/supplierContract/${supplierContractId}/${chatId}`);
  }  
  apiUpdateConfirmationOrderDetail(supplierContractId: any, chatId: any, buildContract: any): Observable<any> {
    localStorage.removeItem('token');
    return this.baseService.put(`/v1/public/update/supplierContract/${supplierContractId}/${chatId}`, buildContract);
  }
}
