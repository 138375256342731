import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return args.discount ? args.defaultPrice - (args.defaultPrice * args.discount / 100) : args.defaultPrice;
  }

}
