import {Component, Input} from '@angular/core';
import {Product} from '../../../../../model/product/product';
import {ProductService} from '../../../../../service/product-service/product.service';
import {BasketService} from '../../../basket/service/basket.service';
import {BasketItem} from "../../../../../model/basket/basket";
import {UserService} from '../../../../../native/user/service/user.service';


@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent {
  @Input() product: Product;
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() loader: boolean = true;
  
  public ImageSrc : string;

  constructor(
    public productService: ProductService,
    public basketService: BasketService,
    public userService: UserService

  ) {
  }

  ngOnInit(): void {}

  onChangeQty(direction: number, product: Product): void {
    this.basketService.itemBasketQtyMap[product._id] = Math.max(Number(this.basketService.itemBasketQtyMap[product._id]) + direction, 0);

    const basketItem = this.basketService.basketItemList.find(basketItem => basketItem.itemId === product._id);
    if (basketItem) {
      this.updateItem(basketItem, this.basketService.itemBasketQtyMap[product._id]);
    } else {
      if (direction > 0) {
        this.basketService.addItemToBasket(product, 1);
      }
    }
  }
  private updateItem(basketItem: BasketItem, qty: number): void {
    this.basketService.updateItemInBasket(basketItem, {qty});
  }

  getPrice(product): number{
    return product.itemSupplierList.filter(supplier => supplier.isDefault).map(supplier => supplier.supplierPrice)[0] || 0;
  }

}
