import {Component, OnInit} from '@angular/core';
import {KpiExpenseByOutletApiService} from "../api/kpi-expense-by-outlet-api.service";
import {lastValueFrom} from "rxjs";
import {KpiExpenseByOutletService} from "../service/kpi-expense-by-outlet.service";

@Component({
  selector: 'app-kpi-expense-by-outlet',
  templateUrl: './kpi-expense-by-outlet.component.html',
  styleUrls: ['./kpi-expense-by-outlet.component.scss']
})
export class KpiExpenseByOutletComponent implements OnInit{
  public isLoading: boolean;
  constructor(
      private kpiExpenseByOutletApiService: KpiExpenseByOutletApiService,
      private kpiExpenseByOutletService: KpiExpenseByOutletService
  ){}
  ngOnInit(): void {
    this.getKPIExpenseByOutlet();
  }

  async getKPIExpenseByOutlet(): Promise<void>{
    this.isLoading = true;
    try {
      const {data} = await lastValueFrom(this.kpiExpenseByOutletApiService.apiGetKpiExpenseByOutlet());
      const chartDom = document.getElementById('kpiExpenseByOutlet');
      this.kpiExpenseByOutletService.buildBarChart(chartDom, data);
    } catch (e) {
      console.error('get kpi expense by outlet failed!', e);
    }
    this.isLoading = false;
  }
}
