import { Injectable } from '@angular/core';
import {BaseService} from '../../../service/base-service/base.service';

@Injectable({
  providedIn: 'root'
})
export class LoginApiService {

  constructor(
      private baseService: BaseService
  ) { }
  
  apiLoginUser(credential: any): any { return this.baseService.post('/v1/user/login/email', credential, true) }
}
