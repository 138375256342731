import { Injectable } from '@angular/core';
import {Chart, registerables} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as _ from 'lodash';
import {DialogService} from "../../../../../native/dialog/service/dialog.service";

@Injectable({
  providedIn: 'root'
})
export class KpiExpenseByOutletService {
  private colorList = [
    'rgba(0,0,255,0.5)',
    'rgba(255,128,128,0.5)',
    'rgba(12,193,246,0.5)',
    'rgba(48,139,220,0.5)',
    'rgba(255,0,0,0.5)',
    'rgba(0,255,0,0.5)',
    'rgba(255,255,0,0.5)',
    'rgba(0,255,255,0.5)',
    'rgba(128,128,128,0.5)',
    'rgba(255,128,0,0.5)',
    'rgba(0,255,128,0.5)',
    'rgba(128,0,255,0.5)',
    'rgba(255,0,128,0.5)',
    'rgba(128,255,0,0.5)',
    'rgba(0,128,255,0.5)',
    'rgba(128,0,128,0.5)',
    'rgba(128,128,255,0.5)',
    'rgba(192,192,192,0.5)',
    'rgba(255,255,255,0.5)',
    'rgba(0,0,0,0.5)',
    'rgba(128,0,0,0.5)',
    'rgba(0,128,0,0.5)',
    'rgba(0,0,128,0.5)',
    'rgba(128,128,0,0.5)',
    'rgba(0,128,128,0.5)',
    'rgba(128,0,128,0.5)',
    'rgba(192,0,0,0.5)',
    'rgba(0,192,0,0.5)',
    'rgba(0,0,192,0.5)',
    'rgba(192,192,0,0.5)',
    'rgba(0,192,192,0.5)',
    'rgba(192,0,192,0.5)',
    'rgba(192,128,0,0.5)',
    'rgba(0,192,128,0.5)',
    'rgba(128,192,0,0.5)',
    'rgba(128,0,192,0.5)',
    'rgba(192,0,128,0.5)',
    'rgba(192,192,128,0.5)',
    'rgba(128,192,192,0.5)',
  ];

  constructor(
      private dialogService: DialogService
  ) {
    Chart.register(...registerables);
  }

  buildBarChart(chartDom, data): void{
    const labelObjectList = this.getMonthLabelList(data);
    const labelList = labelObjectList.map(obj => obj.label);
    const datasetList = this.getDataSetList(data);
      const barChart = new Chart(chartDom, {
        type: 'bar',
        data: {
          labels: labelList,
          datasets: datasetList
        },
        options: {
          backgroundColor: '#1d2242',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: (value, index, values) => {
                  return '$' + new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(
                      Number(value)
                  );
                }
              },
            }
          },
          layout: {
            padding: 6
          },
          onHover: (e, array) => {
          },
          onClick: (e, items) => {
            if (items[0]){
              this.dialogService.open('expenseListDialog', {
                data: {
                  outlet: {
                    id: datasetList[items[0].datasetIndex].outletId,
                    name: datasetList[items[0].datasetIndex].label
                  },
                  month: labelObjectList[items[0].index].month,
                  monthLabel: labelObjectList[items[0].index].monthLabel,
                  year: labelObjectList[items[0].index].year
                }
              });
            }
          },
          plugins: {
            legend: null,
            tooltip: {
              padding: 12,
              titleFont: {
                size: 16,
              },
              bodyFont: {
                size: 14,
              },
              boxPadding: 6,
              callbacks: {
                label: (tooltipItem) => {
                  let label = `${tooltipItem.dataset.label}` || '';
                  if (label) {
                    label += ' = ';
                  }
                  if (tooltipItem.parsed !== null) {
                    label += '$' + new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(
                        tooltipItem.parsed.y
                    );
                  }
                  return label;
                },
              },
            },
            datalabels: {
              display: false
            }
          },
        }
      });
  }

  private getMonthLabelList(data): any{
    const mountList =  ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    return data.map(d => {
      return {
        label: `${d.year} - ${mountList[d.month - 1]}`,
        month: d.month,
        year: d.year,
        monthLabel: mountList[d.month - 1]
      };
    });
  }

  private getDataSetList(data): any{
    const outletList =  this.getOutletList(data);
    const dataSetList = [];

    outletList.map((outlet, i) => {
      dataSetList.push({
        data: data.map(d => d.outletList.filter(
            org => org._id === outlet._id).map(org => org.total) ?? null),
        backgroundColor: this.colorList[i],
        borderColor: this.colorList[i],
        label: outlet.name,
        skipNull: true,
        outletId: outlet._id
      });
    });
    return dataSetList;
  }
  private getOutletList(data): any{
    let outletList = [];
    data.map(d =>{
      outletList = outletList.concat(d.outletList);
    });
    outletList = _.uniqBy(outletList, '_id' );
    return outletList;
  }
}
