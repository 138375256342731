import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input() user;
  @Input() size = 42;
  @Input() borderRadiusPercentage = 100;
  @Input() showOnline: boolean;
  @Input() online: boolean;

  @Input() fullName: string;

  public avatarText;
  public avatarBackground;

  constructor() { }

  ngOnInit(): void {
    if (!this.isEmptyString(this.fullName)) {
      this.user = true;
      this.avatarText = this.fullName[0];
      this.avatarBackground = this.intToRGB(this.hashCode(this.fullName));
      return;
    }
    this.avatarText = this.getAvatarText(this.user);
    this.avatarBackground = this.getAvatarBackground(this.user);
  }

  ngOnChanges(): void {
    if (!this.isEmptyString(this.fullName)) {
      this.user = true;
      this.avatarText = this.fullName[0];
      this.avatarBackground = this.intToRGB(this.hashCode(this.fullName));
      return;
    }
    this.avatarText = this.getAvatarText(this.user);
    this.avatarBackground = this.getAvatarBackground(this.user);
  }

  private getAvatarText(user): string {
    if (!user || !user.firstName || !user.lastName) { return ''; }
    return user.firstName.trim().substring(0, 1).toUpperCase() + user.lastName.trim().substring(0, 1).toUpperCase();
  }

  private getAvatarBackground(user) {
    if (!user || !user.firstName || !user.lastName) { return ''; }
    return this.intToRGB(this.hashCode(user.firstName + ' ' + user.lastName));
  }

  private intToRGB(i) {
    const c = (i & 0x00FFFFFF).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  private hashCode(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) { hash = str.charCodeAt(i) + ((hash << 5) - hash); }
    return hash;
  }
  
  private isEmptyString(str): Boolean {
    return !str || !str.toString().trim();
  }
}
