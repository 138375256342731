import {Component, Input} from '@angular/core';
import {SideMenuService} from '../service/side-menu.service';
import {TranslatorService} from "../../../service/translator-service/translator.service";
import * as _ from 'lodash';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {

  @Input() items;

  constructor(public sideMenuService: SideMenuService,
              public translateService: TranslatorService
  ) {}

  ngOnInit(): void {
    this.sideMenuService.menuToggle = false;
  }

  // Click Toggle menu (Mobile)
  toggleNavActive(item: any): void {
    item.active = !item.active;
    
    const ele = document.getElementById('menu-children-' + item.label);
    ele.style.maxHeight = item.active ? ele.scrollHeight + 'px' : '0px';
  }

  onHover(menuItem: any): void {
    if(window.innerWidth > 1200 && menuItem){
      document.getElementById('unset')?.classList.add('sidebar-unset')
    } else {
      document.getElementById('unset')?.classList.remove('sidebar-unset')
    }
  }

  getCategoryLabel(object: any){
    if(!_.isEmpty(object.labelLanguage)) {
      if (!_.isEmpty(object.labelLanguage[this.translateService.language])) {
        return object.labelLanguage[this.translateService.language];
      }
    }
    return object.label;
  }
}
