<div class="scrollY px-4 py-2" [class.w500]="isDialog">
  <div class="mb-4 row justify-content-between align-items-center" [class.top-sec]="!isDialog">
    <h3 class="col-11">{{ 'myAccount_change_pw_dialog_header' | translate }}</h3>
    <i *ngIf="isDialog" class="ti-close font20 col-1 cursorPointer" (click)="dialog.close()"></i>
  </div>
  <div class="mb-3">
    <label for="form-input-old-password" class="form-label">{{ 'myAccount_change_pw_dialog_current_pw_label' | translate }}</label>
    <input id="form-input-old-password" 
           [(ngModel)]="oldPassword"
           [class.is-invalid]="error.invalid"
           (input)="error = {}"
           class="form-control" 
           type="password" 
           [placeholder]="'myAccount_change_pw_dialog_current_pw_hint' | translate"
    />
  </div>
  <div class="mb-3">
    <label for="form-input-new-password" class="form-label">{{ 'myAccount_change_pw_dialog_new_pw_label' | translate }}</label>
    <input id="form-input-new-password" 
           [(ngModel)]="newPassword"
           [class.is-invalid]="error.notMatch || error.format"
           (input)="error = {}"
           class="form-control" 
           type="password" 
           [placeholder]="'myAccount_change_pw_dialog_new_pw_hint' | translate"
    />
  </div>
  <div class="mb-3">
    <label for="form-input-confirm-new-password" class="form-label">{{ 'myAccount_change_pw_dialog_confirm_new_pw_label' | translate }}</label>
    <input id="form-input-confirm-new-password" 
           [(ngModel)]="confirmNewPassword" 
           [class.is-invalid]="error.notMatch || error.format"
           (input)="error = {}"
           class="form-control" 
           type="password" 
           [placeholder]="'myAccount_change_pw_dialog_confirm_new_pw_hint' | translate"
    />
  </div>
  
  <div *ngIf="error.format" class="alert mb-3 alert-danger" role="alert">
    {{ 'myAccount_change_pw_dialog_error_pw_rule' | translate }}
  </div>
  <div *ngIf="error.notMatch" class="alert mb-3 alert-danger" role="alert">
    {{ 'myAccount_change_pw_dialog_error_pw_not_matching' | translate }}
  </div>
  <div *ngIf="error.invalid" class="alert mb-3 alert-danger" role="alert">
    {{ 'myAccount_change_pw_dialog_error_pw_incorrect' | translate }}
  </div>
  
  <div class="mb-3 positionRelative">
    <div class="fxRow fxRowEnd">
      <button class="btn btn-solid" (click)="onSave()">{{ 'myAccount_change_pw_dialog_save_button' | translate }}</button>
    </div>
    <app-loader-overlay *ngIf="loading"></app-loader-overlay>
  </div>
</div>
