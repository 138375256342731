import {Injectable} from '@angular/core';
import {BaseService} from "../../../../service/base-service/base.service";
import {TranslatorService} from "../../../../service/translator-service/translator.service";
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BasketApiService {
  constructor(
      private baseService:BaseService
              ) {
  }

  // get basket item
  getBasket(): Observable<any> {
    return this.baseService.get('/v1/user/get/basket/detail');
  }

  // add item to basket
  addItemToBasket(basketItem){
    return this.baseService.post('/v1/user/add/item/basket',basketItem);
  }

  // update item in basket
  updateItemInBasket(basketItemId: string, basketItemChange){
    return this.baseService.put(`/v1/user/update/item/basket/${basketItemId}`, basketItemChange);
  }
}
