<div class="fadeIn">
    <div class="collection-wrapper">
        <div class="fxRow">
            <div *ngIf="categoryList && categoryList[0]" class="col-xl-auto">
                <app-side-menu  [items]="categoryList"></app-side-menu>
            </div>
            <div
              infiniteScroll
              [infiniteScrollDistance]="2"
              [infiniteScrollThrottle]="200"
              [scrollWindow]="false"
              (scrolled)="onGetMoreSearchedProduct(key)"
              class="col-xl-auto shop-main h-no-header tab-content"
              [class.fullWidth]="!categoryList || !categoryList[0]"
              id="v-pills-tabContent"
            >
                <div class="tab-pane fade show active px-3" id="grocery">
                    <div id="categoryScrollId"></div>
                    <div  class="title8 pt24">
                        <h4 class="fontBold colorGray">{{'search_product_search_result_label' | translate}}</h4>
                    </div>
                    <div *ngIf="!loader && searchResultList && searchResultList[0]">
                        <section *ngFor="let category of searchResultList;let i= index;" class="pt24">
                            <div class="title8 fxRow fxRowSpaceBetween fxColumnCenter">
                                <div class="fxColumn">
                                    <h2>{{category.label}}</h2>
                                    <div *ngIf="category.description" class="colorGray">{{category.description}}</div>
                                </div>
                                <div *ngIf="i === 0 && numberOfItemPerRowService.ifShowGridIconContainer()"
                                     class="borderCircle foreground p12 cursorPointer">
                                    <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid4Icon()"
                                              (click)="numberOfItemPerRowService.onChangeNumberOfRow(4)"
                                              class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid4-icon.svg"
                                    ></svg-icon>
                                    <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid3Icon()"
                                              (click)="numberOfItemPerRowService.onChangeNumberOfRow(3)"
                                              class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid3-icon.svg"
                                    ></svg-icon>
                                    <svg-icon *ngIf="numberOfItemPerRowService.ifShowGrid6Icon()"
                                              (click)="numberOfItemPerRowService.onChangeNumberOfRow(6)"
                                              class="size20 cursorPointer" src="./assets/image/icon/shop/number-of-row/grid6-icon.svg"
                                    ></svg-icon>
                            </div>

                            </div>
                            <div class="row g-sm-4 g-3">
                                <app-product-box
                                        *ngFor="let product of category.itemList"
                                        [product]="product"
                                        [loader]="loader"
                                        [class.col-6] = "numberOfItemPerRowService.numberOfItemPerRow < 3"
                                        [class.w33p] = "numberOfItemPerRowService.numberOfItemPerRow == 3"
                                        [class.w25p] = "numberOfItemPerRowService.numberOfItemPerRow == 4"
                                        [class.w16p]= "numberOfItemPerRowService.numberOfItemPerRow == 6"
                                ></app-product-box>
                            </div>
                        </section>
                    </div>
                    <div *ngIf="(!searchResultList || !searchResultList[0]) && !loader"
                         class="title8 col-sm-12 text-center section-b-space mt-5 no-found">
                        <img src="./assets/image/img/shop/collection/empty-search.jpg"
                             class="img-fluid mb-4">
                        <h2>{{'search_product_cannot_find_product_label'| translate}}</h2>
                        <div class="sub-des colorGray mt12">{{'search_product_please_check_correct_your_input_label'| translate}}</div>
                    </div>
                    <section>
                        <div class="product-infinitescroll">
                            <div class="theme-paggination-block">
                                <div class="container-fluid p-0">
                                    <div class="fxRow fxRowCenter">
                                        <div class="text-center col-sm-12">
                                            <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                                <img *ngIf="next || loader" class="loading-icon" src="./assets/image/img/product/loader.gif"
                                                     alt="loader" >
                                                <div *ngIf="!next && searchResultList && searchResultList[0]" class="title8">
                                                    <p >{{ 'home_collection_no_more_product' | translate }}</p>
                                                </div>
                                                <p
                                                   class="font24"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <section>
                    <app-footer></app-footer>
                </section>
            </div>
        </div>
    </div>
</div>
