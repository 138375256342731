import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-product-avatar',
  templateUrl: './product-avatar.component.html',
  styleUrls: ['./product-avatar.component.scss']
})
export class ProductAvatarComponent {

  @Input() style;
  @Input() iconSize;
  @Input() src;
}
