import * as _ from 'lodash';
import {v4 as uuid} from 'uuid';
import {environment} from '../../../environments/environment';

export type FileUploaderOptions = {
  prefix: string,
  key?: string,
  value?: any,
  userId?: string,
  maxSize?: number,
  maxQty?: number,
  allowFileType?: string[],
  autoUpload?: boolean,
  error?: FileUploaderErrorType
};

export type FileUploaderResponseType = 'complete' | 'completeAll' | 'afterAddFile' | 'afterAddAll' | 'addFail' | 'uploadFail';
export type FileUploaderErrorType = 'fileSize' | 'fileQty' | 'notAllowType' | 'notSupport';
export type FileUploaderResponse = {
  id: string,
  type: string,
  format: string,
  thumbnail: string,
  mediaName?: string,
  size?: number,
  url: string,
  width?: number,
  height?: number
};

const uploadImageApiString = `${environment.serverDomainName}/v1/b2c/upload/image`;
const uploadFileApiString = `${environment.serverDomainName}/v1/b2c/upload/file/public`;

// uploadResponse(type: FileUploaderResponseType, response: FileUploaderResponse, options: FileUploaderOptions): void {

export class FileUploader {
  private inputElement: any;
  private delegate: any;
  public options: FileUploaderOptions;

  public queueList: any[];

  constructor(delegate: any, fileUploaderOptions: FileUploaderOptions) {
    this.delegate = delegate;
    this.options = _.assign(this.options, fileUploaderOptions);

    this.queueList = [];

    if (!this.options.maxSize) { this.options.maxSize = 10; }
    if (!this.options.maxQty) { this.options.maxQty = 1; }
    if (!this.options.allowFileType || !this.options.allowFileType[0]) { this.options.allowFileType = ['image', 'video', 'file']; }
  }

  async change(e): Promise<void> {
    this.inputElement = e.target;
    if (this.options.maxQty === 1) { this.queueList = []; }
    await this.addToQueue(this.inputElement.files);
    this.clearInputElement();
  }

  async replaceItemInQueue(file): Promise<FileUploaderResponse> {
    if (!file) { return; }
    if (this.checkError(file)) { return; }
    this.queueList.splice(_.findIndex(this.queueList, {name: file.name}), 1, file);
    return await this.buildAddResponse(file);
  }

  // add
  async addToQueue(fileList): Promise<void> {
    if (!fileList || !fileList[0]) { return; }

    // add each file into queue
    for (const file of fileList) {
      // ignore existed file
      if (this.queueList.find(queue => queue.name === file.name)) { continue; }

      const response: FileUploaderResponse = await this.addSingleFile(file);
      if (response) { this.send('afterAddFile', response); }
    }

    // send all item in the queue, after add all
    const responseList = [];
    for (const queue of this.queueList) {
      const response = await this.buildAddResponse(queue);
      responseList.push(response);
    }
    if (responseList && responseList[0]) { this.send('afterAddAll', responseList); }

    // check if auto upload
    if (this.options.autoUpload) { await this.uploadAll(); }
  }
  removeFromQueue(fileName): void { this.queueList = this.queueList.filter(queue => queue.name !== fileName); }
  clearQueue(): void { this.queueList = []; this.clearInputElement(); }
  private async addSingleFile(file): Promise<FileUploaderResponse> {
    if (this.checkError(file)) { return null; }

    this.queueList = this.queueList.filter(queue => queue.name !== file.name);
    this.queueList.push(file);
    return await this.buildAddResponse(file);
  }
  private async buildAddResponse(file): Promise<FileUploaderResponse> {
    const fileUrl = await this.convertFileToUrl(file);
    const fileType = this.getFileType(file);

    return {
      id: uuid(),
      type: fileType,
      mediaName: file.name,
      size: file.size,
      format: file.name.split('.').pop(),
      thumbnail: fileUrl,
      url: fileUrl
    };
  }

  // upload
  async uploadAll(): Promise<void> {
    const mediaList: any[] = [];
    const fileList: any[] = [];

    for (const queue of this.queueList) {
      const fileType = this.getFileType(queue);
      if (fileType === 'file') {
        fileList.push(queue);
      } else {
        mediaList.push(queue);
      }
    }

    const mediaResponseList: FileUploaderResponse[] = [];
    for (const image of mediaList) {
      const mediaResponse: FileUploaderResponse = await this.uploadImage(image);
      mediaResponseList.push(mediaResponse);
      this.send('complete', mediaResponse);
    }

    const fileResponseList: FileUploaderResponse[] = [];
    for (const file of fileList) {
      const fileResponse: FileUploaderResponse = await this.uploadFile(file);
      fileResponseList.push(fileResponse);
      this.send('complete', fileResponse);
    }

    this.send('completeAll', [...mediaResponseList, ...fileResponseList]);
    this.clearQueue();
  }
  private async uploadImage(image): Promise<FileUploaderResponse> {
    const publicId = [environment.imageProjectPrefix, this.options.prefix, this.options.userId, new Date().getTime()]
      .filter(data => data).join('_');

    const formData = new FormData();
    formData.append('image', image, image.name);
    formData.append('publicId', publicId);
    formData.append('fileName', image.name);

    const headers = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    headers.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'POST',
      headers,
      body: formData
    };

    const response = await fetch(uploadImageApiString, requestOptions);
    this.queueList = this.queueList.filter(queue => queue.name !== image.name);
    return await response.json();
  }
  private async uploadFile(file): Promise<FileUploaderResponse> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('fileName', file.name);

    const headers = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    headers.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'POST',
      headers,
      body: formData
    };

    const response = await fetch(uploadFileApiString, requestOptions);
    this.queueList = this.queueList.filter(queue => queue.name !== file.name);
    return await response.json();
  }

  // response
  private send(type: FileUploaderResponseType, response: FileUploaderResponse | FileUploaderResponse[]): void {
    if (this.delegate?.uploadResponse && typeof this.delegate.uploadResponse === 'function') {
      this.delegate.uploadResponse(type, response, this.options);
    }
  }

  // input
  private clearInputElement(): void { if (this.inputElement) { this.inputElement.value = ''; } }

  // helper
  private async convertFileToUrl(file): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
      reader.onerror = reject;
    });
  }
  private checkError(file): boolean {
    // too many files
    if (this.queueList.length >= this.options.maxQty) {
      this.options.error = 'fileQty';
      this.send('addFail', null);
      return true;
    }

    // file too large
    if (file.size > 1000 * 1000 * this.options.maxSize) {
      this.options.error = 'fileSize';
      this.send('addFail', null);
      return true;
    }

    // file not allow
    const fileType = this.getFileType(file);
    if (!this.options.allowFileType.includes(fileType)) {
      this.options.error = 'notAllowType';
      this.send('addFail', null);
      return true;
    }

    this.options.error = null;
    return false;
  }
  // noinspection JSMethodCanBeStatic
  private getFileType(file): string {
    const fileType = file.type.split('/')[0];
    return ['image', 'video'].includes(fileType) ? fileType : 'file';
  }
}
