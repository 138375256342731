<div class="h400 borderRadius p12 backgroundDivider mt12 fxColumn">
    <div class="title8">
        <h2>
            {{'home_top_ordered_product_title'| translate}}
        </h2>
    </div>
    <div class="table-container fullHeight fxColumn fadeIn borderRadius">
        <!--header-->
        <div class="fullWidth noSelect fxRow fxRowSpaceBetween fxColumnCenter py12">
            <div class="w15p fontBold"></div>
            <div class="w50p fontBold">
                {{'home_top_ordered_product_name_label'| translate}}
            </div>
            <div class="w20p fontBold">
                {{'home_top_ordered_product_supplier_label'| translate}}
            </div>
            <div class="w15p fxRow fxRowCenter fxColumnCenter fontBold">
                {{'home_top_ordered_product_total_order_label'| translate}}
            </div>
        </div>
        <!--loading-->
        <div *ngIf="isLoading" class="fullWidth fullHeight fxRow fxRowCenter fxColumnCenter">
            <img src="./assets/image/img/shop/collection/loader.gif" class="loading-icon img-fluid">
        </div>
        <!--empty-->
        <div *ngIf="productList.length <= 0 && !isLoading"
             class="fullWidth fullHeight fxRow fxRowCenter fxColumnCenter">
            <div class="textGray font20 noSelect fontSemiBold">{{ 'No Data yet'| translate}}</div>
        </div>
        <!--data-->
        <div *ngIf="productList.length> 0 && !isLoading" class="fullHeight fullWidth table-body"
             infiniteScroll
             [infiniteScrollUpDistance]="2"
             [infiniteScrollThrottle]="50"
             [scrollWindow]="false"
             (scrolledUp)="getMoreMostOrderedProduct()">
            <div *ngFor="let product of productList">
                <div class="fxRow fxRowSpaceBetween fxColumnCenter row cursorPointer" (click)="onProductDetail(product)">
                    <div class="w15p">
                        <app-product-avatar [iconSize]="16" [style]="{width: '40px', height: '40px', margin: '0 12px'}" [src]="product.thumbnail"></app-product-avatar>
                    </div>
                    <div class="w50p textOverflow">{{product.name}}</div>
                    <div *ngIf="product?.supplier?.name" class="w20p textOverflow">{{product.supplier.name}}</div>
                    <div *ngIf="!product?.supplier?.name" class="w20p">-</div>
                    <div class="w15p fxRow fxRowCenter fxColumnCenter">{{product.orderCount}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
