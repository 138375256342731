import { Injectable } from '@angular/core';
import {BaseService} from "../../../../../service/base-service/base.service";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MostOrderedProductApiService {

  constructor(
      private baseService: BaseService
  ) {
  }

  apiGetMostOrderItem(next: number = 0, limit: number = 10): Observable<any> {
    return this.baseService.get(`/v1/user/get/most/order/item/list/${next}/${limit}`);
  }
}
