import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Injectable({
  providedIn: 'root'
})
export class NumberOfItemPerRowService  implements OnInit ,OnDestroy{
  public numberOfItemPerRow: number;
  private xSmallScreenSub;
  private smallScreenSub;
  private mediumScreenSub;
  private webScreenSub;
  constructor(
      private breakpointObserver: BreakpointObserver,
  ) {
      //detect when resize screen
    const xSmallScreen = this.breakpointObserver.observe(Breakpoints.XSmall);
    const smallScreen = this.breakpointObserver.observe(Breakpoints.Small);
    const mediumScreen = this.breakpointObserver.observe(Breakpoints.Medium);
    const webScreen = this.breakpointObserver.observe(Breakpoints.Web);

      this.xSmallScreenSub = xSmallScreen.subscribe(result => {
          if (result.matches) {
              this.numberOfItemPerRow = 2;
          }
      });

      this.smallScreenSub = smallScreen.subscribe(result => {
          if (result.matches) {
              this.numberOfItemPerRow = 3;
          }
      });

      this.mediumScreenSub = mediumScreen.subscribe(result => {
          if (result.matches) {
              this.numberOfItemPerRow = 4;
          }
      });

      this.webScreenSub = webScreen.subscribe(result => {
          if (result.matches) {
              this.numberOfItemPerRow = 6;
          }
      });

        // detect for suitable number per row
      if(window.innerWidth < 600){
          this.numberOfItemPerRow = 2;
      }
       else if(window.innerWidth < 960) {
          this.numberOfItemPerRow = 3;
      } else if(window.innerWidth < 1280) {
          this.numberOfItemPerRow = 4;
      } else {
          this.numberOfItemPerRow = 6;
      }
  }
  ngOnInit(): void {

  }
  onChangeNumberOfRow(count: number){
    this.numberOfItemPerRow = count;
  }
  ifShowGridIconContainer(): boolean{
      return window.innerWidth > 600;
  }
  ifShowGrid6Icon(): boolean{
      return window.innerWidth > 1280 && this.numberOfItemPerRow === 3;
  }

  ifShowGrid4Icon(): boolean{
      return (window.innerWidth > 1280 && this.numberOfItemPerRow === 6) ||
          (window.innerWidth < 1280 && this.numberOfItemPerRow === 3);
  }
  ifShowGrid3Icon(): boolean{
        return this.numberOfItemPerRow === 4;
  }

  ngOnDestroy(): void {
  this.xSmallScreenSub.unsubscribe();
  this.smallScreenSub.unsubscribe();
  this.mediumScreenSub.unsubscribe();
  this.webScreenSub.unsubscribe();
  }

}
