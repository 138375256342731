import { Component, Input } from '@angular/core';
import {Breadcrumb} from '../model/breadcrumb';
import {NavService} from '../../../service/nav-service/nav.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() breadcrumb: Breadcrumb;
  @Input() showBackButton: boolean;
  
  constructor(
    public navService: NavService,
    private router: Router
  ) {
  }
  
  onBack() {
    this.router.navigateByUrl(this.navService.previousRoute);
  }
  
}
