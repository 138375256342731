
<div class="h80vh fxColumn p12 w70vw">
        <!--header-->
        <div class="fxRow fxRowSpaceBetween fxColumnCenter fullWidth mb12">
            <div class="fxRow fxColumnCenter">
                <div class="dot"></div>
                <div class="ml6">
                    <span class="font20 fontBold">{{'expense_in_title_label' | translate}}</span>
                    <span class="font20 fontBold textPrimary mx6">{{dialog.options.data.outlet.name}}</span>
                    <span class="font20 fontBold">{{'expense_of_title_label' | translate}}</span>
                    <span class="font20 fontBold mx6 textPrimary">{{dialog.options.data.monthLabel}}</span>
                    <span class="font20 fontBold">-</span>
                    <span class="font20 fontBold ml6 textPrimary">{{dialog.options.data.year}}</span>
                </div>
            </div>
            <div class="fxRow fxColumnCenter">
                <svg-icon src="./assets/image/icon/shop/cart/close-icon.svg" class="size16 cursor mx6" (click)="onClose()"></svg-icon>
            </div>
        </div>
        <div class="expense-table shadowPrimary fullHeight fullWidth fxColumn fadeIn">
            <div class="fullWidth">
                <div class="expense-table-header fullWidth noSelect fxRow fxRowSpaceBetween fxColumnCenter">
                    <div class="expense-head w20p fxRow fxRowSpaceBetween fxColumnCenter">
                        <div class="expense-head-title">{{'expense_order_number_label' | translate}}</div>
                    </div>
                    <div class="expense-head w20p fxRow fxRowSpaceBetween fxColumnCenter">
                        <div class="expense-head-title">{{'expense_customer_label' | translate}}</div>
                    </div>
                    <div class="expense-head w20p fxRow fxRowSpaceBetween fxColumnCenter">
                        <div class="expense-head-title">{{'expense_outlet_label' | translate}}</div>
                    </div>
                    <div class="expense-head w20p fxRow fxRowSpaceBetween fxColumnCenter">
                        <div class="expense-head-title">{{'expense_delivery_date_label' | translate}}</div>
                    </div>
                    <div class="expense-head w20p fxRow fxRowSpaceBetween fxColumnCenter">
                        <div class="expense-head-title">{{'expense_total_label' | translate}}</div>
                    </div>
                </div>
                <hr/>
            </div>
            <!--loading-->
            <div *ngIf="isLoading" class="fullWidth fullHeight fxRow fxRowCenter fxColumnCenter">
                <img src="./assets/image/img/shop/collection/loader.gif" class="loading-icon img-fluid">
            </div>
            <!--empty-->
            <div *ngIf="!isLoading && expenseList.length <= 0"
                 class="fullHeight fxRow fxRowCenter mt128">
                <div class="font20 textGray noSelect fontSemiBold">{{'expense_no_data_label' | translate }}</div>
            </div>
            <!--data-->
            <div *ngIf="!isLoading && expenseList.length > 0"
                 class="expense-table-body fullWidth fullHeight"
                 infiniteScroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="50"
                 [scrollWindow]="false"
                 (scrolled)="getMoreExpenseListByOutlet()">
                <div *ngFor="let expense of expenseList">
                    <div class="fxRow fxColumnCenter fullWidth expense-row px6">
                        <div class="w20p pl6 textOverflow">#{{expense?.number}}</div>
                        <div class="w20p pl6 textOverflow">{{expense.customer?.firstName | titlecase}}
                            {{expense.customer?.lastName | titlecase}}</div>
                        <div class="w20p fxRow fxColumnCenter ">
                            <app-product-avatar class="px12" [src]="expense.deliveryAddress?.photoUrl" [iconSize]= "40"
                                                [style]="{width: '50px', height: '50px'}"></app-product-avatar>
                            <div class="textOverflow fontSemiBold ml6">{{expense.deliveryAddress?.name}}</div>
                        </div>
                        <div *ngIf="expense.deliveredAt" class="pl6 w20p textOverflow">{{expense.deliveredAt | date : 'dd MMM, yyyy hh:mm a'}}</div>
                        <div *ngIf="!expense.deliveredAt" class="pl6 w20p textOverflow">{{'expense_asps_label' | translate}}</div>
                        <div class="pl6 w20p textOverflow fontSemiBold">${{expense.total | number:'1.2-2'}}</div>
                    </div>
                    <hr/>
                </div>
            </div>
        </div>
    </div>
