import {Component, OnInit} from '@angular/core';
import {Breadcrumb} from "../../../native/breadcrumb/model/breadcrumb";
import {TranslatorService} from "../../../service/translator-service/translator.service";
import {DialogService} from "../../../native/dialog/service/dialog.service";
import {NavigationExtras, Router} from "@angular/router";
import {TicketApiService} from "../api/ticket-api.service";
import {firstValueFrom} from "rxjs";
import {Ticket} from "../model/ticket";

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit{
  public breadCrumb: Breadcrumb;
  public searchWord: string;
  public isLoading: boolean;
  public next: number;
  public isNext: boolean;
  public ticketList : Ticket[];

  constructor(
      private translatorService: TranslatorService,
      private dialogService: DialogService,
      private router: Router,
      private ticketApiService: TicketApiService
      ){
    this.ticketList= [];
    this.searchWord = '';
    this.next = 0;
  }

  async ngOnInit() {
    await this.initBreadcrumb();
    await this.getTicketList();
  }
  private async initBreadcrumb() {
    this.breadCrumb = new Breadcrumb({
      title: await this.translatorService.translate('ticket_breadcrumb_title'),
      breadcrumbList: [
        {
          label: await this.translatorService.translate('ticket_breadcrumb_link')
        }
      ]
    });
  }
  private async getTicketList() : Promise<void> {
    try {
      this.isLoading = true;
      const response =  await firstValueFrom(this.ticketApiService.getTicketList(this.next,15));
      this.ticketList = this.ticketList.concat(response.list);
      this.next = response.next;
      this.isNext = response.next !== null;
      this.isLoading = false;
    } catch(e) {
      this.isLoading = false;
      console.error('fail to get model', e);
    }
  }

  public async onScroll(e) {
    const dom = e.target;
    if (dom.scrollHeight - (dom.scrollTop + dom.offsetHeight) < 400) {
      if (this.isNext && !this.isLoading) {
        await this.getTicketList();
      }
    }
  }
  public onGotoTicketDetail(ticket): void {
    let navigationExtras: NavigationExtras = {
      state: { ticket: JSON.stringify(ticket)},
    };

    this.router.navigate(["/home/support/",ticket.ticketNumber],  navigationExtras);
  }
  public onOpenAddTicketDialog(): void {
    const addTicketDialog = this.dialogService.open('addTicketDialog');
    const addTicketSub = addTicketDialog.eClose.subscribe(data => {
      if (data) {
        this.ticketList.splice(0, 0, data);
      }
      addTicketSub.unsubscribe();
    });
  }
  public capitalizeFirstLetter(text: string): string {
    text = text ? text.charAt(0).toUpperCase() + text.substr(1).toLowerCase() : '';
    return text;
  }
}
