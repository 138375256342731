<div *ngIf="isLoaded" class="h-no-header fadeIn scrollY" id="section-my-account">
  <app-breadcrumb [breadcrumb]="breadCrumb"></app-breadcrumb>
  <section class="dashboard-section section-b-space user-dashboard-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="dashboard-sidebar">
            <div class="profile-top">
              <div class="fxRowCenter fxRow positionRelative">
                <app-avatar (click)="profile.click()" class="cursorPointer" [user]="userService.getUser()" [size]="150"></app-avatar>
                <input type="file" #profile hidden (change)="profileUploader.change($event)" accept="image/*" />
                <app-loader-overlay *ngIf="isUploading"></app-loader-overlay>
              </div>
              <div class="profile-detail">
                <h5>{{ user.firstName }} {{ user.lastName }}</h5>
                <h6>{{ user.email }}</h6>
              </div>
            </div>
            <div class="faq-tab">
              <ul class="nav nav-tabs overflow-y-hidden" id="top-tab" role="tablist">
                <li class="nav-item"><a class="nav-link py-2 cursorPointer" (click)="changeTab(1)" [class.active]="openTab === 1">{{ 'myAccount_left_menu_account_info' | translate }}</a></li>
                <li class="nav-item"><a class="nav-link py-2 cursorPointer" (click)="changeTab(2)" [class.active]="openTab === 2">{{ 'myAccount_left_menu_address_book' | translate }}</a></li>
                <li class="nav-item"><a class="nav-link py-2 cursorPointer" (click)="changeTab(3)" [class.active]="openTab === 3">{{ 'myAccount_left_menu_my_order' | translate }}</a></li>
                <li class="nav-item"><a class="nav-link py-2 cursorPointer" (click)="changeTab(4)" [class.active]="openTab === 4">{{ 'myAccount_left_menu_security' | translate }}</a> </li>
                <li class="nav-item"><a class="nav-link py-2 cursorPointer text-danger" (click)="logout()">{{ 'myAccount_left_menu_logout' | translate }}</a> </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="faq-content tab-content" id="top-tabContent">
            <div class="tab-pane fade" [class]="openTab === 1 ? 'active show' : ''" id="info">
              <div class="counter-section">
                <div class="welcome-msg">
                  <h4>{{ 'myAccount_info_tab_header_hello_user' | translate: {username: user.firstName + ' ' + user.lastName} }}</h4>
                  <p>{{ 'myAccount_info_tab_header_subtitle' | translate }}</p>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="counter-box">
                      <img src="./assets/image/img/my-account/total-order.png" class="img-fluid">
                      <div>
                        <h3>{{ totalOrder }}</h3>
                        <h5>{{ 'myAccount_info_tab_order_status_total_order' | translate }}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="counter-box">
                      <img src="./assets/image/img/my-account/pending-order.png" class="img-fluid">
                      <div>
                        <h3>{{ totalPendingOrder }}</h3>
                        <h5>{{ 'myAccount_info_tab_order_status_pending_order' | translate }}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="counter-box">
                      <img src="./assets/image/img/my-account/wishlist.png" class="img-fluid">
                      <div>
                        <h3>{{ basketService.basketItemList.length }}</h3>
                        <h5>{{ 'myAccount_info_tab_order_status_cart' | translate }}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box-account box-info">
                  <div class="box-head">
                    <h4>{{ 'myAccount_info_tab_section_account_information' | translate }}</h4>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="box">
                        <div class="box-title">
                          <h3>{{ 'myAccount_info_tab_title_contact_information' | translate }}</h3>
                        </div>
                        <div class="box-content">
                          <h6>{{ user.firstName }} {{ user.lastName }}</h6>
                          <h6>{{ user.email }}</h6>
                          <h6><a href="javascript:void(0)" (click)="onChangePassword()">{{ 'myAccount_info_tab_change_password_button' | translate }}</a></h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box mt-3">
                    <div class="box-title">
                      <h3>{{ 'myAccount_info_tab_title_address_book' | translate }}</h3>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 row row-gap-3">
                        <h6>{{ 'myAccount_info_tab_subtitle_delivery_address' | translate }}</h6>
                        <address *ngIf="userAddressList?.length <= 0">You have not set a default shipping address.</address>
                        <div *ngFor="let address of userAddressList" class="col-md-6 fxRow">
                          <app-product-avatar [src]="address?.photoUrl" [iconSize]= "50"  [style]="{width: '70px', height: '70px'}"></app-product-avatar>
                          <div class="fxColumn ml6">
                            <div class="fontBold colorPrimary">{{address?.name}}</div>
                            <div class="colorGray font14">{{address?.address}}</div>
                            <div *ngIf="address?.contactList && address.contactList[0]" class="colorGray font14">
                              <div *ngFor="let contact of address.contactList" class="fxRow gap6">
                                <div *ngIf="contact.firstname || contact.lastname">{{ contact.firstname }}&nbsp;{{ contact.lastname }}</div>
                                <div *ngIf="contact.phone">&nbsp; +{{ contact.countryCode + contact.phone }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [class]="openTab === 2 ? 'active show' : ''" id="address">
              <div class="row">
                <div class="col-12">
                  <div class="card mt-0">
                    <div class="card-body">
                      <div class="top-sec">
                        <h3>{{ 'myAccount_address_tab_header_address_book' | translate }}</h3>
                      </div>
                      <div class="address-book-section">
                        <div class="row g-4">
                          <div *ngFor="let address of userAddressList" class="col-md-6 fxRow">
                            <app-product-avatar [src]="address?.photoUrl" [iconSize]= "50"  [style]="{width: '70px', height: '70px'}"></app-product-avatar>
                            <div class="fxColumn ml6">
                              <div class="fontBold colorPrimary">{{address?.name}}</div>
                              <div class="colorGray font14">{{address?.address}}</div>
                              <div *ngIf="address?.contactList && address.contactList[0]" class="colorGray font14">
                                <div *ngFor="let contact of address.contactList" class="fxRow gap6">
                                  <div *ngIf="contact.firstname || contact.lastname">{{ contact.firstname }}&nbsp;{{ contact.lastname }}</div>
                                  <div *ngIf="contact.phone">&nbsp; +{{ contact.countryCode + contact.phone }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [class]="openTab === 3 ? 'active show' : ''" id="orders">
              <div class="row">
                <div class="col-12">
                  <div class="card dashboard-table mt-0">
                    <div class="card-body table-responsive-sm">
                      <div class="top-sec">
                        <h3>{{ 'myAccount_order_tab_header_my_order' | translate }}</h3>
                      </div>
                      <div class="table-responsive-xl">
                        <table class="table cart-table text-align-start">
                          <thead>
                            <tr class="table-head">
                              <th scope="col">{{ 'myAccount_order_tab_table_head_order_number' | translate }}</th>
                              <th scope="col" class="text-start">{{ 'myAccount_order_tab_table_head_delivery_to' | translate }}</th>
                              <th scope="col">{{ 'myAccount_order_tab_table_head_delivery_date' | translate }}</th>
                              <th scope="col" *ngIf="userService.isAllowViewPrice">{{ 'myAccount_order_tab_table_head_price' | translate }}</th>
                              <th scope="col">{{ 'myAccount_order_tab_table_head_status' | translate }}</th>
                              <th scope="col">{{ 'myAccount_order_tab_table_head_view' | translate }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let myOrder of myOrderList">
                              <td>
                                <span class="mt-0">#{{ myOrder.number }}</span>
                              </td>                              
                              <td class="text-start">
                                <div class="fxRow fxColumnCenter gap-2">
                                  <app-product-avatar [src]="myOrder.deliveryAddress?.photoUrl" [iconSize]="30" [style]="{width: '50px', height: '50px'}"></app-product-avatar>
                                  <span>
                                    <div>{{ myOrder.deliveryAddress.name }}</div>
                                    <p>{{ myOrder.deliveryAddress.address }}</p>
                                  </span>
                                </div>
                              </td>
                              <td *ngIf="myOrder.deliveredAt">{{ myOrder.deliveredAt | date: 'dd/MM/yyyy hh:mm a' }}</td>
                              <td *ngIf="!myOrder.deliveredAt">{{ 'myAccount_order_asap' | translate }}</td>
                              <td *ngIf="userService.isAllowViewPrice">
                                <span>{{ myOrder.total | currency: productService.Currency.currency:'symbol' }}</span>
                              </td>
                              <td>
                                <span class="badge rounded-pill custom-badge"
                                      [class.bg-info]="myOrder.status === 'CONFIRMED'"
                                      [class.bg-danger]="myOrder.status === 'PROCESSING'"
                                      [class.bg-warning]="myOrder.status === 'PENDING'"
                                      [class.bg-success]="myOrder.status === 'DELIVERED'"
                                      [class.bg-secondary]="myOrder.status === 'REJECTED' || myOrder.status === 'INCOMPLETE'"
                                >{{ myOrder.status }}</span>
                              </td>
                              <td>
                                <a href="javascript:void(0)" (click)="viewOrderDetail(myOrder)">
                                  <i class="fa fa-eye text-theme"></i>
                                </a>
                              </td>
                            </tr>
                            <tr *ngIf="!myOrderList || !myOrderList[0]">
                              <td colspan="5">
                                <p class="m-2">{{ 'myAccount_order_tab_table_no_order_yet' | translate }}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div *ngIf="orderNext !== null || orderNext === 0" class="fxRow fxRowCenter mt-4 position-relative">
                          <button (click)="getMoreMyOrder()" class="btn btn-solid">{{ 'myAccount_order_tab_load_more_button' | translate }}</button>
                          <app-loader-overlay *ngIf="loadingMoreOrder"></app-loader-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" [class]="openTab === 4 ? 'active show' : ''" id="security">
              <div class="row">
                <div class="col-12">
                  <div class="card mt-0">
                    <div class="card-body">
                      <app-change-password-dialog></app-change-password-dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
<div class="h-no-header positionRelative">
  <app-loader-overlay *ngIf="!isLoaded"></app-loader-overlay>
</div>
