<!-- section start -->
<section class="p-0">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="error-section">
          <h1>{{'error_error_not_found_code'| translate}}</h1>
          <h2>{{'error_error_not_found_message'| translate}}</h2>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Section ends -->
