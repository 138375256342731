import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Router} from "@angular/router";
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private serverDomainName: string;
  private headers: HttpHeaders;
  public token: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.serverDomainName = environment.serverDomainName;
  }
  getToken(): void{
    const noneTokenScreen = this.router.url.split('/')[0] === '';

    if (!this.token) { this.token = JSON.parse(localStorage.getItem('token')); }
    if (!this.token && !noneTokenScreen) { this.router.navigate(['']); }

    if (this.token) { this.headers = new HttpHeaders({Authorization: `Bearer ${this.token}`}); }
  }

  get(url: string): any {
    this.getToken();
    return this.httpClient.get(`${this.serverDomainName}${url}`, { headers: this.headers }); }
  post(url: string, data: any, noneToken = false): any {
    if (!noneToken) { this.getToken(); }
    this.getToken();
    return this.httpClient.post(`${this.serverDomainName}${url}`, data, noneToken? {} : { headers: this.headers }); }
  put(url: string, data: any): any {
    this.getToken();
    return this.httpClient.put(`${this.serverDomainName}${url}`, data, { headers: this.headers });
  }
  delete(url: string): any {
    this.getToken();
    return this.httpClient.delete(`${this.serverDomainName}${url}`, { headers: this.headers });

  }

  buildQuery(query: object): string {

    const queryList = [];
    for (const key in query) {
      if (!query.hasOwnProperty(key)) { continue; }
      let value = query[key];

      // not include query, when empty string or null
      if (['', null].includes(value)) { continue; }

      // convert to string when it a object
      if (typeof query[key] === 'object') {
        if (_.isEmpty(value)) { continue; }
        value = JSON.stringify(query[key]);
      }

      // add query
      queryList.push(`${key}=${value}`);
    }

    const queryString = queryList.join('&');
    return queryString ? `?${queryString}` : '';
  }
}
