import { Component } from '@angular/core';
import {Breadcrumb} from "../../../native/breadcrumb/model/breadcrumb";
import {TermConditionService} from "../service/term-condition.service";

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.scss']
})
export class TermAndConditionComponent {
  public breadcrumb: Breadcrumb;

  constructor(public  termConditionService: TermConditionService){
    this.breadcrumb = new Breadcrumb({
      title: 'TERM AND CONDITION',
      breadcrumbList: [
        {
          label: 'Term and Condition'
        }
      ]
    });
  }
  ngOnInit() {
    this.termConditionService.onGetData('termCondition');
  }
}
